* {
  user-select: none;
}

.pagination-outer {
  text-align: center;
}

.pagination {
  display: inline-flex;
  padding: 10px 15px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 50px;
  position: relative;
}

.pagination li a.page-link {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 5px 15px;
  background: #f4f4f4;
  border: none;
  border-radius: 0;
  color: #1092a3;
  /* box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2); */
  transition: all 0.3s ease 0s;
}

.pagination li:first-child a.page-link {
  border-radius: 30px 0 0 30px;
  margin-right: 5px;
}

.pagination li:last-child a.page-link {
  border-radius: 0 30px 30px 0;
  margin-left: 5px;
}

.pagination li.active a.page-link,
.pagination li a.page-link:hover,
.pagination li.active a.page-link:hover {
  background: #1f2328;
  color: #fff;
  border: none;
  background-color: unset !important;
  border-color: unset !important;
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.6);
}

@media only screen and (max-width: 479px) {
  .pagination {
    display: block;
  }

  .pagination li {
    display: inline-block;
  }
}