body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Roboto Regular", "Roboto Medium", "Roboto Medium",
    "Roboto Bold""Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Roboto Regular";
  src: url("../public/fonts/roboto-regular-webfont.woff") format("woff"),
    url("../public/fonts/roboto-regular-webfont.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto Medium";
  src: url("../public/fonts/roboto-medium-webfont.woff") format("woff"),
    url("../public/fonts/roboto-medium-webfont.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto Bold";
  src: url("../public/fonts/roboto-bold-webfont.woff") format("woff"),
    url("../public/fonts/roboto-bold-webfont.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Nunito Regular";
  src: url("../public/fonts/Nunito-Regular.woff") format("woff"),
    url("../public/fonts/Nunito-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Nunito Medium";
  src: url("../public/fonts/Nunito-Medium.woff") format("woff"),
    url("../public/fonts/Nunito-Medium.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Chakra Petch Regular";
  src: url("../public/fonts/ChakraPetch-Regular.woff") format("woff"),
    url("../public/fonts/ChakraPetch-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Chakra Petch Bold";
  src: url("../public/fonts/ChakraPetch-Bold.woff") format("woff"),
    url("../public/fonts/ChakraPetch-Bold.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins Regular";
  src: url("../public/fonts/PoppinsRegular.woff") format("woff"),
    url("../public/fonts/PoppinsRegular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins Bold";
  src: url("../public/fonts/PoppinsBold.woff") format("woff"), url("../public/fonts/PoppinsBold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#root {
  background: #1f2328;
  height: 100vh;
  width: 100%;
  overflow: auto;
}

.nftm-forms-main {
  max-width: 828.9px;
  margin: 0 auto;
  padding-bottom: 100px;
  padding-top: 55px;
}

.nftm-form-content {
  background: #ffffff;
  border: 1px solid #e9eff5;
  border-radius: 10px;
  padding: 62px 64px 118px 64px;
}

.nftm-form-content h2 {
  font-family: "Roboto Bold";
  font-size: 30px;
  line-height: 20px;
  color: #0b0b0b;
  margin: 0px;
}

.divider-forms {
  height: 1px;
  width: 100%;
  margin: 37px 0px;
  background-color: rgba(235, 238, 246, 0.42);
}

.left-menu-main .divider-forms {
  height: 1px;
  width: 100%;
  margin: 27px 0px 19px 0px;
  background-color: rgba(235, 238, 246, 0.42);
}

.divider-modal {
  margin: 14px 0px 24px 0px;
  height: 1px;
  width: 100%;
  background-color: rgba(235, 238, 246, 0.42);
}

.nftm-form-content form label {
  width: 100%;
  display: flex;
}

.nftm-form-content form input {
  width: 100%;
  background: rgba(235, 238, 246, 0.42);
  border-radius: 5px;
  border: none;
  padding: 24px 23px;
  margin-bottom: 30px;
}

.nftm-form-content form input::placeholder {
  font-family: "Roboto Regular";
  font-size: 18px;
  line-height: 24px;
  color: #72819f;
}

.login-error {
  width: 100%;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-error p {
  font-size: 13px;
  color: red;
  margin: 0;
}

.login-btn {
  background: linear-gradient(90deg, #7b33eb 21.89%, rgba(1, 100, 216, 0.71) 98.16%) !important;
  border-radius: 30px !important;
  border: none !important;
  width: 100% !important;
  font-family: "Roboto Bold";
  font-size: 20px !important;
  line-height: 20px !important;
  color: #cad6ec !important;
  padding: 21px 0px !important;
  text-transform: uppercase;
}

.login-footer {
  display: flex;
  width: 100%;
  padding-top: 40px;
}

.forget-footer {
  display: flex;
  width: 100%;
  /* padding-top: 40px; */
}

.contact-info.login-informtion {
  margin-top: 17px;
}

.forget-footer .login-create-link a img {
  width: 17px;
  height: 17px;
  padding: 0px 10px;
}

.forget-footer .login-create-link a {
  font-family: "Nunito Regular";
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-decoration: underline;
  display: inline-flex;
  align-items: center;
}

.login-links {
  width: 70%;
  float: left;
}

.login-forget {
  text-align: right;
  width: 30%;
}

.login-create-link {
  font-family: "Nunito Regular";
  font-size: 16px;
  line-height: 24px;
  color: #353535;
}

.login-create-link a {
  color: #0967d2;
  text-decoration: none;
}

.login-forget {
  text-align: right;
  width: 50%;
  font-family: "Roboto Bold";
  font-size: 14px;
  line-height: 14px;
  text-decoration-line: underline;
  color: #0967d2;
}

.signup-cont .error-msg {
  padding-top: 27px;
  font-family: "Roboto Regular";
  margin-top: -20px !important;
  font-size: 20px;
  line-height: 20px;
  color: #97adc4;
  display: block;
}

.nftm-forms-logo {
  width: 250px;
  height: auto;
  margin-bottom: 20px;
}

.forget-footer .login-forget {
  font-family: "Nunito Medium";
}

/* .passport-requirements .form-check .form-check-input:checked::before,
.passport-requirements .form-check .form-check-input:checked::after {
  background-color: #0967d2;
  background-image: url("../public/images/check-icon.png");
  border-radius: 50%;
} */

.passport-requirements .form-check {
  display: flex;
  align-items: center;
  width: 50%;
  float: left;
  margin-bottom: 17px;
  padding-left: 0px;
}

.passport-requirements .form-check input {
  width: auto !important;
}

.passport-requirements h3 {
  font-family: "Roboto Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  color: #353535;
  margin: 0;
  margin-bottom: 31px;
}

.passport-requirements {
  padding-bottom: 20px;
  display: flow-root;
}

.passport-requirements label {
  font-family: "Roboto Bold";
  font-size: 14px;
  line-height: 24px;
  color: #353535;
  padding-left: 14px;
}

.signup-footer .signup-agre {
  font-family: "Roboto Regular";
  font-size: 14px;
  line-height: 24px;
  color: #878787;
  padding-bottom: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.signup-footer a {
  font-family: "Roboto Regular";
  font-size: 14px;
  line-height: 24px;
  color: #878787;
  text-decoration: underline;
  padding-left: 4px;
  padding-right: 4px;
}

.signup-create-link span {
  font-family: "Roboto Regular";
  font-size: 14px;
  line-height: 24px;
  color: #353535;
  text-align: left;
  padding: 0px 0px 7px 0px;
}

.signup-create-link a {
  font-family: "Roboto Regular";
  font-size: 14px;
  line-height: 24px;
  color: #0967d2;
}

.signup-create-link {
  display: flex;
  flex-direction: column;
}

.forget-cont span {
  font-family: "Roboto Bold";
  font-size: 16px;
  line-height: 24px;
  color: #97adc3;
  padding-top: 27px;
  display: flex;
}

.forget-footer .login-create-link {
  font-family: "Nunito Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #353535;
}

.dashboard-links a {
  font-family: "Roboto Bold";
  font-size: 14px;
  line-height: 16px;
  color: #cad6ec;
  padding: 7px 19px;
  text-decoration: none;
  margin-bottom: 80px;
  text-transform: uppercase;
  width: 100%;
  float: left;
  align-items: center;
}

.dashboard-links a {
  display: flex;
  font-family: "Roboto Bold";
  font-size: 10px;
  line-height: 20px;
  color: #cad6ec;
}

.dashboard-links a.active .smartContractsLinks {
  background: linear-gradient(90deg, #7b33eb 21.89%, rgba(9, 133, 246, 0.71) 98.16%);
}

.dashboard-links a .smartContractsLinks {
  background: #2e343a;
  border-radius: 50%;
  color: #cad6ec;
  width: 52px;
  height: 52px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.smartContractsLinksContent {
  font-family: "Roboto Bold";
  font-size: 14px;
  line-height: 16px;
}

.smartContractsLinksContent span {
  width: 100%;
  display: block;
  font-size: 9px;
  line-height: 24px;
  color: #c6c6e1;
  font-family: "Roboto Bold";
}

.dashboard-links a.active,
.dashboard-links a.active span {
  background: linear-gradient(90deg, #7b33eb 21.89%, rgba(9, 133, 246, 0.71) 98.16%);
  border-radius: 8px;
  color: #cad6ec;
}

.dashboard-links a:hover,
.dashboard-links a:hover span {
  background: linear-gradient(90deg, #7b33eb 21.89%, rgba(9, 133, 246, 0.71) 98.16%);
  border-radius: 8px;
  color: #cad6ec;
  text-decoration: none;
}

.dashboard-links a.active .smartContractsLinks img {
  display: block !important;
}

.dashboard-links a .smartContractsLinks img {
  display: none !important;
}

.dashboard-links a .smartContractsLinks span {
  display: block !important;
  font-size: 22px;
  line-height: 26px;
  color: #c6c6e1;
  font-family: "Roboto Bold";
}

.dashboard-links a.active .smartContractsLinks span {
  display: none !important;
}

.dashboard-collection {
  background: #1d1e22;
  border-radius: 10px;
  margin: 21px 14px;
  padding: 12px 18px;
}

.dashboard-collection-cont-list h4 {
  font-family: "Roboto Bold";
  font-size: 18px;
  line-height: 20px;
  color: #cad6ec;
  margin-bottom: 0px;
}

.dashboard-collection-cont {
  padding-left: 24px;
}

.dashboard-collection-cont-list span {
  font-family: "Roboto Bold";
  font-size: 6px;
  line-height: 20px;
  color: #2e343a;
}

.dashboard-collection img {
  width: 72px;
  height: 72px;
  object-fit: contain;
  border-radius: 5px;
}

.dashboard-collection {
  background: #1d1e22;
  border-radius: 10px;
  margin: 21px 14px;
  padding: 12px 18px;
  display: flex;
  align-items: center;
}

.left-menu-main.smart-left-menus {
  max-width: 351px;
  z-index: 9;
}

.content-main.left-menu.smart-left-content {
  margin-left: 351px;
}

.dashboard-links {
  max-height: 75vh;
  width: 100%;
  padding: 21px 14px 50px 14px;
  overflow-y: auto;
}

.left-menu-main {
  width: 250px;
  display: flex;
  background-color: #1f2328;
  height: 850px;
}

::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.right-menu-main {
  display: flex;
  flex-direction: column;
  background-color: #1f2328;
  padding: 0 10px;
  height: 850px;
}

.right-menu-content label .copy-icon {
  position: absolute;
  z-index: 99;
  right: 10px;
  bottom: 10px;
  cursor: pointer;
}

.header-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #2E343A;
}

.header-profile span {
  font-family: "Roboto Regular";
  font-size: 16px;
  line-height: 24px;
  color: #cad6ec;
}

.header-profile .dropdown-menu::before {
  display: none;
}

.header-logo {
  max-width: 191px;
  width: 100%;
  height: 84px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 38px;
}

.header-logo img {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.header-logo a:hover {
  text-decoration: none;
  color: rgba(123, 51, 235, 1);
}

.header-logo a {
  font-family: "Roboto Bold";
  font-size: 40px;
  line-height: 70px;
  text-align: left;
  color: rgba(123, 51, 235, 1);
  text-align: center;
}

.header-link {
  display: flex;
  align-items: center;
}

.header-profile img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 30px;
}

.header-profile {
  display: flex;
  align-items: center;
  border-left: 1px solid #2e343a;
  padding-right: 10px;
  padding-left: 10px;
  width: 295px !important;
  justify-content: center;
}

.header-profile .dropdown button {
  font-family: "Roboto Regular";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #cad6ec !important;
  background-color: transparent !important;
  border: none;
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}

.header-profile .dropdown-menu.show .header-menu-open .dropdown-item:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.header-profile .dropdown .dropdown-toggle::after {
  border: none;
  background-image: url(../public/images/down-icon.png);
  width: 14px;
  height: 7.64px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.header-profile .dropdown button:focus {
  box-shadow: none !important;
}

.header-profile .dropdown-menu {
  /* min-width: 35rem; */
  width: 100%;
  padding: 0;
  margin: 30px 0px 0px 0px;
  border: none;
  box-shadow: none;
  background-color: transparent;
  position: absolute !important;
  top: 3px !important;
  left: -4px !important;
}

.search-icon {
  position: absolute;
  left: 10px;
  z-index: 9;
  height: 100%;
  display: flex;
  align-items: center;
}

.search-dash .input-group input {
  padding-left: 40px;
  background: #1d1e22;
  border: 1px solid #cad6ec;
  box-sizing: border-box;
  border-radius: 8px !important;
  font-family: "Roboto Regular";
  font-size: 16px;
  line-height: 20px;
  color: #cad6ec;
  height: 50px;
}

.header-menu-open a {
  font-family: "Roboto Regular";
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  padding: 23px;
  border: 1px solid #e9eff5;
  background-color: #f7f9fb;
  width: 295px;
}

.header-menu-open a img {
  width: 40px;
  height: 40px;
  object-fit: contain;
  border-radius: 0px;
  margin-right: 40px;
}

.content-main {
  background-color: #ffffff;
  display: flex;
  /* justify-content: space-between; */
  height: calc(100vh - 85px);
}

.content-main-black {
  background-color: #1f2328;
  padding: 25px 40px 0px 40px;
}

.content-main-black.left-menu {
  margin-left: 191px;
}

.content-main-black.right-menu {
  margin-right: 277px;
}

.content-main.left-menu {
  margin-left: 191px;
}

.content-main.right-menu {
  margin-right: 277px;
}

.myAccount-cont .contact-info h3 {
  font-family: "Roboto Bold";
  font-size: 20px;
  color: #0b0b0b;
  line-height: 20px;
  padding-bottom: 33px;
  margin-bottom: 0;
}

.myAccount-cont form label {
  font-family: "Roboto Bold";
  font-style: normal;
  font-weight: 900;
  font-size: 10px;
  line-height: 12px;
  width: 100%;
  color: #4f5b76;
}

.myAccount-cont form .dash-input input {
  background: rgba(157, 157, 157, 0.04);
  border-radius: 5px;
  border: none;
  width: 100%;
  padding: 17px;
  margin-bottom: 16px;
}

button.change-password-btn {
  border-radius: 5px !important;
  background: transparent;
  font-family: "Roboto Bold";
  font-size: 10px;
  line-height: 20px;
  color: #1f2328;
  margin-bottom: 20px;
  padding: 0px 0px;
  margin-right: 10px;
  border: double 2px transparent;
  background-image: linear-gradient(white, white), radial-gradient(circle at top left, #7b33eb, rgba(1, 100, 216, 0.71));
  background-origin: border-box;
  background-clip: content-box, border-box;
  width: 158px;
  height: 36px;
}

.save-btn {
  width: 158px;
  height: 37px;
  background: linear-gradient(90deg, #7b33eb 21.89%, rgba(1, 100, 216, 0.71) 98.16%);
  border-radius: 8px;
  border: none;
  font-family: "Roboto Bold";
  font-size: 10px;
  line-height: 20px;
  color: #cad6ec;
  text-transform: uppercase;
  margin-top: 30px;
}

.myAccount-cont {
  width: calc(100% - 220px);
  padding: 31px 45px;
  overflow-y: scroll;
}

.left-menu-button-box {
  width: 220px;
  height: 100%;
  background: #1d1e22;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.myAccountBtn {
  width: 163px;
  height: 34px;
  background: none;
  border-radius: 8px;
  border: none;
  font-family: 'Roboto Bold';
  font-style: normal;
  font-weight: 900;
  font-size: 10px;
  line-height: 20px;
  text-align: left;
  color: #CAD6EC;
  margin-top: 10px;
  padding: 0 18px;
}

.btn-style {
  background: linear-gradient(90deg, #7B33EB 21.89%, rgba(9, 133, 246, 0.71) 98.16%);
}

.myAccount-cont .error-msg {
  margin-top: -10px !important;
}

.myAccount-cont form {
  margin-bottom: 33px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

/* .dashboard-image-upload .upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.dashboard-image-upload .upload-btn {
  color: #bfcedd;
  font-family: "Roboto Regular";
  padding: 8px 20px;
  border-radius: 8px;
  font-size: 16px;
  line-height: 20px;
}

.dashboard-image-upload .upload-btn.btn:focus {
  box-shadow: none;
}

.error-msg {
  color: red !important;
  margin-top: -30px !important;
  margin-bottom: 30px;
  font-size: 14px !important;
  line-height: 16px !important;
  padding-top: 2px !important;
}

.dashboard-image-upload .upload-btn-wrapper input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
} */

.dashboard-upload {
  padding-top: 30px;
}

.dashboard-image-upload {
  background: #1d1e22;
  border: 2px dashed #c0cedd;
  box-sizing: border-box;
  border-radius: 5px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 300px;
}

.upload-btn span {
  font-family: "Roboto Regular";
  font-size: 16px;
  line-height: 20px;
  color: #bfcedd;
  width: 100%;
  float: left;
  padding-top: 16px;
}

.dashboard-upload .card {
  background: #2b2d34;
  width: 344px;
}

.dashboard-upload .card-title {
  font-family: "Roboto Bold";
  font-size: 18px !important;
  line-height: 20px !important;
  color: #cad6ec;
  margin-bottom: 0px !important;
}

.dashboard-upload .dash-upload-left {
  align-items: center;
  display: flex;
}

.dashboard-upload .dash-upload-right {
  text-align: right;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.dashboard-upload button.card-btns {
  background-color: #2e343a;
  border-radius: 5px;
  border: 0px;
  margin-left: 5px;
  padding: 5px;
  margin-bottom: 5px;
}

.dashboard-upload button.card-btns:active,
.dashboard-upload button.card-btns:focus {
  box-shadow: none !important;
  background-color: #2e343a !important;
  border-color: transparent !important;
}

.dashboard-upload button.card-btns img {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.dashboard-upload .dropdown-menu.show {
  background: #ffffff;
  box-shadow: 0px 0px 40px rgba(26, 63, 105, 0.1);
  border-radius: 5px;
  min-width: 200px;
  left: -90px !important;
  border: 0;
  padding: 0px;
  transform: none !important;
}

.dashboard-upload .dropdown-menu.show .dropdown-item {
  font-family: "Roboto Regular";
  font-size: 14px;
  line-height: 16px;
  color: #535f77;
  padding: 14px 17px;
}

.dashboard-upload .archiveCollection.dropdown-item {
  background: #f44336;
  color: #fff !important;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.dashboard-upload .dropdown-menu.show .dropdown-item img {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}

.dash-upload-right .dropdown-menu.show::before {
  left: 45%;
  top: -20px;
  border-bottom: 10px solid #fff;
}

.dash-upload-right .dropdown-menu::before {
  content: "";
  width: 0px;
  height: 0px;
  border: 0.8em solid transparent;
  position: absolute;
}

.dash-upload-right .dropdown-menu {
  background-color: #fff;
  background-image: -moz-linear-gradient(top, #444, #444);
  position: relative;
  color: #ccc;
  padding: 10px;
  border-radius: 3px;
  box-shadow: 0px 0px 20px #999;
  margin: 40px 25px 25px 4px;
  min-height: 50px;
  border: 1px solid #333;
  text-shadow: 0 0 1px #000;
}

.dashboard-upload .dashboard-cards:hover .image-open {
  display: block;
  position: absolute;
  top: 10px;
  left: 30px;
  background: #2e343a;
  border-radius: 5px;
  border: 0px;
  padding: 2px 5px;
  cursor: pointer;
}

.dashboard-upload .dashboard-cards .image-open {
  display: none;
}

.dashboard-cards {
  position: relative;
  width: 344px !important;
  height: 427px !important;
  padding: 0 !important;
  box-sizing: border-box !important;
  margin-left: 25px;
}

.card-head {
  padding:0 !important;
  width: 100%;
  height: 307px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffff !important;
  cursor: pointer;
}


.fill-image {
  object-fit: fill;
  width: 100px !important;
}

.dashboard-upload .image-open img {
  width: 20px;
  object-fit: contain;
  height: 20px;
}

.right-menu-content {
  padding: 5px 25px;
}

.right-menu-content .right-menu-main .right-menu-hdgs {
  font-family: "Roboto Bold";
  font-size: 8px;
  line-height: 20px;
  color: #a8bbce;
  background: #2b2d34;
  padding: 6px 10px;
}

.right-menu-content .right-menu-main .right-menu-hdgs img {
  width: 10px;
  height: 10px;
  margin-right: 10px;
  object-fit: contain;
}

.right-menu-content .right-menu-form label {
  font-family: "Roboto Bold";
  font-size: 12px;
  line-height: 20px;
  color: #cad6ec;
  width: 100%;
  text-transform: uppercase;
  position: relative;
}

.right-menu-content .right-menu-form label input.copy-input {
  padding-right: 30px;
}

.right-menu-content .right-menu-form select {
  background: #f7f9fb;
  border: 1px solid #cad6ec;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  font-family: "Roboto Regular";
  font-size: 14px;
  line-height: 12px;
  text-align: left;
  color: #101010;
  padding: 8px 10px;
}

.right-menu-content .right-menu-form label input {
  width: 100%;
  background: #f7f9fb;
  border: 1px solid #cad6ec;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 6px 10px;
}

.right-menu-hdgs {
  font-family: "Roboto Bold";
  font-size: 8px;
  line-height: 20px;
  color: #a8bbce;
  background: #2b2d34;
  padding: 5px 8px;
}

.right-menu-hdgs img {
  margin-right: 10px;
  width: 10px;
  height: 10px;
  object-fit: contain;
}

.right-menu-content .right-menu-form label img {
  width: 12px;
  height: 12px;
  margin-left: 10px;
  object-fit: contain;
}

.trait-switch .toggle-button-cover {
  position: relative;
  float: left;
  margin-left: 10px;
}

.trait-switch .button-cover,
.trait-switch .knobs,
.trait-switch .layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 30px;
}

.trait-switch .button {
  position: relative;
  width: 50px;
  height: 23px;
}

.trait-switch .checkbox {
  position: relative;
  /* width: 50px;
  height: 23px; */
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}

.trait-switch .knobs {
  z-index: 2;
}

.trait-switch .layer {
  width: 100%;
  background-color: #cad6ec;
  transition: 0.3s ease all;
  z-index: 1;
}

.trait-switch #button-10 .knobs:before,
.trait-switch #button-10 .knobs:after,
.trait-switch #button-10 .knobs span {
  position: absolute;
  top: 4px;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  transition: 0.3s ease all;
  border-radius: 30px;
  padding: 2px 0px;
}

.trait-switch #button-10 .knobs:before {
  content: "";
  left: 4px;
  background: radial-gradient(94.85% 994.56% at 5.15% 100%, #7b33eb 12.62%, rgba(120, 33, 255, 0.74) 98.44%);
  width: 16px;
  height: 16px;
}

.trait-switch #button-10 .knobs:after {
  content: "#";
  right: 8px;
  color: #4e4e4e;
}

.trait-switch #button-10 .knobs span {
  display: inline-block;
  left: 8px;
  color: #fff;
  z-index: 1;
}

.trait-switch #button-10 .checkbox:checked+.knobs span {
  color: #4e4e4e;
}

.trait-switch #button-10 .checkbox:checked+.knobs:before {
  left: 31px;
}

.trait-switch #button-10 .checkbox:checked+.knobs:after {
  color: #fff;
}

.trait-switch #button-10 .checkbox:checked~.layer {
  background-color: #cad6ec;
}

.trait-switch label {
  float: left;
  width: auto !important;
}

.progress-values {
  width: 100%;
  display: contents;
  float: left;
}

.progress-values input {
  width: 100% !important;
}

.progress-values input:focus,
.progress-values input:focus-visible {
  outline: none;
}

.delete-btn {
  background: #1f2328;
  border: 1px solid #e04558;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: "Roboto Bold";
  font-size: 10px;
  color: #e04558;
  line-height: 20px;
  width: 100%;
  display: block;
  padding: 7px;
  text-align: center;
  margin: 13px auto 30px;
  max-width: 228px;
}

.delete-btn:hover {
  background: #e04558;
  color: #ffffff;
}

.traitsBasic-links {
  padding: 15px;
  position: relative;
  height: 100%;
}

.left-menu-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 15px;
}

.traitsBasic-upload .upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.traitsBasic-upload .upload-btn {
  /* border: 2px solid gray; */
  color: gray;
  /* background-color: white; */
  padding: 8px 20px;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
}

.traitsBasic-upload .upload-btn-wrapper input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
}

.traitsBasic-upload {
  background: #fcfcfc;
  border: 2px dashed #e2e7ec;
  box-sizing: border-box;
  padding: 56px 20px 24px 20px;
  text-align: center;
}

.traitsBasic-links .nav-tabs .nav-link.active {
  background: linear-gradient(90deg, #7b33eb 21.89%, rgba(9, 133, 246, 0.71) 98.16%);
  border-radius: 8px;
  border: none;
  font-family: "Roboto Bold";
  font-size: 6px;
  line-height: 7px;
  color: #1f2328;
  width: 100%;
  padding: 5px;
}

.traitsBasic-links .nav-tabs li.nav-item {
  width: 50%;
  justify-content: center;
  display: flex;
}

.traitsBasic-links .nav-tabs .nav-link {
  font-family: "Roboto Bold";
  font-style: normal;
  font-weight: 900;
  font-size: 6px;
  line-height: 7px;
  background-color: transparent;
  color: #cad6ec;
  margin: 0px 5px;
  width: 100%;
}

.traitsBasic-links .nav-tabs {
  justify-content: space-evenly;
  background: #1d1e22;
  border-radius: 24px;
  border: none;
  padding: 3px;
  display: flex;
  align-items: center;
  height: 30px;
}

.traitsBasic-links .nav-tabs .nav-link:hover {
  background: linear-gradient(90deg, #7b33eb 21.89%, rgba(9, 133, 246, 0.71) 98.16%);
  border-radius: 8px;
  border: none;
  padding: 5px;
}

.trait-basic-background-btn.active {
  background: rgba(157, 157, 157, 0.04);
  border: 1px solid #ffb800;
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 6px;
  margin-bottom: 10px;
  position: relative;
}

.trait-basic-background-btn {
  background: rgba(157, 157, 157, 0.04);
  border: 1px solid #cad6ec;
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 6px;
  position: relative;
  margin-bottom: 10px;
  width: 100%;
}

.trait-basic-background-btn h4 {
  font-family: "Roboto Bold";
  font-size: 10px;
  line-height: 20px;
  color: #cad6ec;
  margin-bottom: 0;
  margin-left: 4px;
}

.trait-basic-background-btn.select {
  background-color: #fff;
}

.trait-basic-background-btn.select h4 {
  color: #000000;
}

.trait-basic-background-btn.select span {
  color: #000000;
}

.trait-basic-background-btn span {
  font-family: "Roboto Bold";
  font-size: 5px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #cad6ec;
  margin-left: 4px;
}

.trait-basic-add-trait .input-group form {
  width: 100%;
  margin-bottom: 16px;
}

.trait-basic-add-trait .input-group {
  height: 33px;
}

.trait-basic-add-trait .input-group form .add-trait-btn {
  position: absolute;
  top: 0;
}

.trait-basic-btn-numbering {
  float: right;
  background: #1d1e22;
  border-radius: 3px;
  font-family: "Roboto Bold";
  font-size: 5px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #cad6ec;
  position: absolute;
  right: 10px;
  padding: 0px 8px;
  width: 25px;
}

.trait-basic-tab h3 {
  font-family: "Roboto Bold";
  font-size: 9px;
  line-height: 20px;
  color: #cad6ec;
}

.trait-basic-add-trait .left-menu-form {
  display: flex;
}

.add-trait-btn img {
  width: 9px;
  height: 9px;
  object-fit: contain;
}

.add-trait-btn {
  border-radius: 3px;
  width: 19px;
  height: 20px;
  border: double 1px transparent;
  background-image: linear-gradient(#1f2328, #1f2328),
    radial-gradient(circle at top left, #7b33eb, rgba(1, 100, 216, 0.71));
  background-origin: border-box;
  background-clip: content-box, border-box;
  padding: 0;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -47px;
}

.trait-basic-add-trait .left-menu-form .custom-select {
  background: #1d1e22 !important;
  border-radius: 3px;
  border: none;
  font-family: "Roboto Bold";
  font-size: 9px;
  line-height: 20px;
  color: #cad6ec;
  margin-right: 5px;
}

.custom-select {
  background: url(/public/images/dropdown-arrow.png) right 0.75rem center/6px 5px no-repeat !important;
}

.preview-trait-btn {
  border-radius: 5px;
  font-family: "Roboto Bold";
  font-size: 10px;
  line-height: 20px;
  color: #cad6ec;
  width: 100%;
  margin-bottom: 8px;
  border: double 2px transparent;
  background-image: linear-gradient(#1f2328, #1f2328),
    radial-gradient(circle at top left, #7b33eb, rgba(1, 100, 216, 0.71));
  background-origin: border-box;
  background-clip: content-box, border-box;
  padding: 0;
  background-color: transparent;
  height: 33px;
}

.preview-trait-btn:hover {
  background: linear-gradient(90deg, #7b33eb 21.89%, rgba(9, 133, 246, 0.71) 98.16%);
}

.export-trait-btn {
  background: linear-gradient(90deg, #7b33eb 21.89%, rgba(9, 133, 246, 0.71) 98.16%);
  border-radius: 8px;
  border: none;
  font-family: "Roboto Bold";
  font-size: 10px;
  line-height: 20px;
  color: #1f2328;
  padding: 6px;
  width: 100%;
}

.export-trait-btn img {
  float: left;
  padding-top: 3px;
}

.traitsBasic-upload .upload-btn span {
  font-family: "Roboto Regular";
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #cad6ec;
  margin-bottom: 5px;
  font-weight: 300;
}

.header-profile .dropdown {
  transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.traitsBasic-upload .upload-btn p {
  font-family: "Roboto Regular";
  font-size: 8px;
  font-weight: 300;
  line-height: 12px;
  text-align: center;
  color: #cad6ec;
}

.traitsBasic-upload-list {
  width: 100px;
  height: 114px;
  margin-right: 12px;
  margin-bottom: 12px;
}

.traitsBasic-upload-list-cont img {
  width: 115px;
  height: 136px;
  object-fit: cover;
  margin: 0 auto;
}


.active-image {
  border: 2px solid #7b33eb;
  width: 120px !important;
  background-origin: border-box;
  background-clip: content-box, border-box;
  padding: 0;
  background-color: transparent;
  object-fit: cover;
}

.active-image img {
  width: 120px;
}

.active-image div {
  width: 118px;
}

.traitsBasic-upload-list-cont {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: auto;
  width: 120px;
  margin-top: 15px;
  margin-right: 10px;
}

.traitsBasic-list-aper button {
  margin: 0;
  width: 19px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  border-radius: 50%;
  visibility: hidden;
}

.traitsBasic-upload-list-cont:hover .traitsBasic-list-aper button {
  visibility: visible;
}

.traitsBasic-list-aper span {
  background: #cad6ec;
  border: 1px solid #cad6ec;
  box-sizing: border-box;
  border-radius: 5px;
  font-family: "Roboto Regular";
  font-size: 8px;
  line-height: 10px;
  color: #1f2823;
  padding: 2px 3px;
  float: right;
}



.traitsBasic-list-aper img {
  width: 15px;
  height: 15px;
  border: none;
}

.traitsBasic-list-aper {
  width: 100%;
  padding: 6px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: absolute;
  top: 0;
  left: 0;

}

.traitsBasic-upload-list-name {
  width: 120px;
  height: 30px;
  background: #2B2D34;
  font-family: 'Roboto Bold';
  font-style: normal;
  font-weight: 900;
  font-size: 9px;
  display: flex;
  align-items: center;
  color: #CAD6EC;
  padding: 0 7px;
}

.collection-image-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.image-dimension-notCorrect {
  width: 120px;
  height: 164px;
  background-image: url(../public/images/defaultImage.jpg);
  background-position: center;
  background-size: cover;
  margin-top: 15px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: relative;
}

.image-dimension-notCorrect-message {
  width: 100%;
  height: 100%;
  background: rgba(224, 69, 87, 0.746);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto Regular';
  font-style: normal;
  font-weight: lighter;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  padding: 15px;
  letter-spacing: 0.5px;
  color: #CAD6EC;
  position: absolute;
  top: 0;
  left: 0;
}

.new-collection-modal-main .modal-dialog {
  margin: 15vh auto;
  border-radius: 16px;
  max-width: 576px !important;
}

.new-collection-modal-main .modal-content {
  border-radius: 16px;
}

.confirm-order-modal .modal-content {
  background-color: #1f2328;
}

.new-collection-modal-main .modal-content .modal-body {
  padding: 30px;
}

.new-collection-modal-content h2 {
  font-family: "Roboto Bold";
  font-size: 20px;
  line-height: 20px;
  color: #0b0b0b;
  margin-bottom: 10px;
}

.new-collection-modal-content p {
  font-family: "Roboto Regular";
  font-size: 12px;
  line-height: 20px;
  color: #97adc4;
}

.modal-devider {
  background: #ecf3fb;
  height: 1px;
  margin: 14px 0px;
}

.smart-modal-devider {
  background: #2e343a;
  margin: 14px 0px 25px;
  height: 1px;
}

.new-collection-modal-content label {
  font-family: "Roboto Bold";
  font-size: 10px;
  line-height: 12px;
  color: #4f5b76;
  width: 100%;
  position: relative;
}

.form-group .form-check {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

/* .new-collection-modal-content label span {
  right: 75px;
  position: absolute;
} */
.new-collection-modal-content .form-group .form-check input .form-check-input {
  width: 14px;
}

.new-collection-modal-content label input::placeholder {
  color: #a5acb8;
}

.new-collection-modal-content label select::placeholder {
  color: #a5acb8;
}

.new-collection-check {
  background: #252a30;
  border: 0.5px solid #2e343a;
  box-sizing: border-box;
  border-radius: 5px;
  margin: 18px 0px 25px 0px;
  padding: 17px 31px;
}

.new-collection-modal-content .new-collection-check label {
  font-family: "Roboto Regular";
  width: 100%;
  position: relative;
  font-size: 12px;
  line-height: 18px;
  color: #cad6ec;
  letter-spacing: 0.5px;
}

/* .new-collection-modal-main .modal-content {
  background-color: #1f2328;
} */
.btn-purchase-export {
  background: linear-gradient(90deg, #7b33eb 0%, rgba(26, 140, 255, 0) 177.69%);
  border-radius: 6px;
  border: none;
  font-family: "Roboto Bold";
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #ffffff;
  width: 100%;
  justify-content: center;
  height: 56px;
}

.btn-purchase-export:hover {
  opacity: 0.8;
}


.apply-btn {
  background: linear-gradient(90deg, #7b33eb 0%, rgba(26, 140, 255, 0) 177.69%);
  border-radius: 30px;
  border: none;
  font-family: "Roboto Bold";
  font-size: 9px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #ffffff;
  width: 74px;
  justify-content: center;
  position: absolute;
  bottom: 14px;
  right: 10px;
}

.cancel-btn {
  background: #E04558;
  border-radius: 30px;
  border: none;
  font-family: "Roboto Bold";
  font-size: 9px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #ffffff;
  width: 74px;
  justify-content: center;
  position: absolute;
  bottom: 14px;
  right: 10px;
}

.apply-btn:hover {
  opacity: 0.8;
}

.new-collection-modal-content label input {
  width: 100%;
  background: rgb(251 251 251);
  border-radius: 5px;
  border: none;
  padding: 17px 20px;
  font-family: "Roboto Regular";
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}

.new-collection-modal-content label textarea {
  width: 100%;
  background: rgba(157, 157, 157, 0.04);
  border-radius: 5px;
  border: none;
  padding: 17px 20px;
  font-family: "Roboto Regular";
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  min-height: 102px;
}

.confirm-order-modal select {
  height: 54px;
  border: none;
  background: rgb(251 251 251);
  border-radius: 5px;
}

.confirm-order-modal select option {
  font-family: "Roboto Regular";
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}

.confirm-order-modal label input {
  width: 100%;
  background: rgb(251 251 251);
  border-radius: 5px;
  border: none;
  padding: 17px 20px;
  font-family: "Roboto Regular";
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}

.confirm-order-modal label textarea {
  width: 100%;
  background: rgba(157, 157, 157, 0.04);
  border-radius: 5px;
  border: none;
  padding: 17px 20px;
  font-family: "Roboto Regular";
  font-size: 14px;
  line-height: 20px;
  color: #97adc4;
  min-height: 102px;
}

.confirm-order-modal label select {
  width: 100%;
  background: rgb(251 251 251);
  border-radius: 5px;
  border: none;
  padding: 17px 20px;
  font-family: "Roboto Regular";
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}

.confirm-order-modal h2 {
  font-family: "Roboto Bold";
  font-size: 24px;
  line-height: 33px;
  display: flex;
  align-items: center;
  color: #cad6ec;
}

.new-collection-modal-content label .ImageDemension input:first-child {
  margin-right: 10px;
}

.new-collection-modal-content label .ImageDemension input:last-child {
  margin-left: 10px;
}

.new-collection-your-price {
  font-family: "Roboto Regular";
  font-size: 16px;
  line-height: 38px;
  color: #cad6ec;
  position: relative;
  padding: 10px 0px;
  display: flex;
}

.new-collection-your-price span {
  position: absolute;
  right: 0;
  font-family: "Roboto Bold";
  font-size: 30px;
  line-height: 38px;
  text-align: center;
  color: #cad6ec;
  display: flex;
}

.new-collection-your-price span p {
  font-family: "Roboto Bold" !important;
  font-size: 10px !important;
  display: flex;
  align-items: center;
  text-align: center;
  color: #cad6ec !important;
  margin-right: 5px;
}

.old_price {
  position: absolute;
  right: 10px;
  top: 45px;
  font-size: 16px !important;
  text-decoration: line-through;
}

.ImageDemension {
  display: flex;
}

.ImageDemension input {
  max-width: 100px;
}

.ImageDemension span {
  font-family: "Roboto Regular";
  font-size: 14px;
  line-height: 20px;
  color: rgba(157, 157, 157, 0.4);
  margin: 0px 17px;
  align-items: center;
  display: flex;
}

input:focus,
input:focus-visible {
  outline: 1px solid #7b33eb;
}

textarea:focus,
textarea:focus-visible {
  outline: 1px solid #7b33eb;
}

.new-collection-modal-content .login-btn {
  margin-top: 15px;
}

.image-collection-modal-main .modal-dialog {
  border-radius: 16px;
  max-width: 420px !important;
  margin: 20px auto;
}

.image-collection-modal-content h2 {
  font-family: "Roboto Bold";
  font-size: 20px;
  line-height: 20px;
  color: #0b0b0b;
  margin-bottom: 10px;
}

.image-collection-modal-content p {
  font-family: "Roboto Regular";
  font-size: 12px;
  line-height: 20px;
  color: #97adc4;
}

.image-collection-modal-content label {
  width: 100%;
}

button.btn-upload {
  background: linear-gradient(90deg, #7b33eb 21.89%, rgba(1, 100, 216, 0.71) 98.16%) !important;
  border-radius: 30px !important;
  border: none !important;
  width: 100% !important;
  font-family: "Roboto Bold";
  font-size: 20px !important;
  line-height: 20px !important;
  color: #cad6ec !important;
  padding: 18px 5px 17px 5px !important;
  text-transform: uppercase;
}

/* button.btn-blue {
  background: #344e5d;
  border: 0px;
  padding: 18px 5px 17px 5px !important;
  margin-right: 10px;
  width: 80px;
  border-radius: 30px !important;
  color: #cad6ec !important;
  font-family: "Roboto Bold";
  font-size: 20px !important;
  line-height: 20px !important;
  text-transform: uppercase;
} */

.btn-blue {
  background: linear-gradient(90deg, #7b33eb 21.89%, rgba(1, 100, 216, 0.71) 98.16%) !important;
  border-radius: 30px !important;
  border: none !important;
  width: 71px !important;
  font-family: "Roboto Bold";
  font-size: 20px !important;
  line-height: 20px !important;
  color: #cad6ec !important;
  padding: 13px 20px !important;
  text-transform: uppercase;
  margin-right: 9px;
}

.btn-blue img {
  width: 31px;
}

.image-collection-modal-btns {
  margin-top: 32px;
  display: flex;
}

.image-collection-modal-main .modal-dialog .modal-content {
  border-radius: 16px !important;
}

.image-collection-modal-main .modal-content .modal-body {
  padding: 30px;
}

.image-collection-modal-content img {
  width: 100%;
}

.btn-delete {
  background: linear-gradient(90deg, #7b33eb 21.89%, rgba(1, 100, 216, 0.71) 98.16%) !important;
  border: none !important;
  width: 100% !important;
  font-family: "Roboto Bold";
  font-size: 20px !important;
  line-height: 20px !important;
  color: #cad6ec !important;
  padding: 18px 10px !important;
  text-transform: uppercase;
  border-radius: 6px;
}

.btn-connect-wlt {
  background: linear-gradient(90deg, #7b33eb 21.89%, rgba(1, 100, 216, 0.71) 98.16%) !important;
  border: none !important;
  width: 100% !important;
  font-family: "Roboto Bold";
  font-size: 20px !important;
  line-height: 20px !important;
  color: #cad6ec !important;
  padding: 18px 10px !important;
  text-transform: uppercase;
  border-radius: 6px;
  margin-top: 19px;
}

.btn-amount {
  background: #fff;
  border: none !important;
  width: 100% !important;
  font-family: "Roboto Bold";
  font-size: 18px !important;
  line-height: 18px !important;
  color: #000000 !important;
  padding: 18px 10px !important;
  text-transform: uppercase;
  border-radius: 5px;
  text-align: center;
}

.delete-modal-main .modal-content {
  background: #1f2328;
  border-radius: 16px;
}

.delete-modal-content h2 {
  font-family: "Roboto Bold";
  font-size: 24px;
  line-height: 20px;
  color: #cad6ec;
  margin-bottom: 16px;
}

.delete-modal-content p {
  font-family: "Roboto Regular";
  font-size: 14px;
  line-height: 18px;
  color: #cad6ec;
  margin-bottom: 0px;
}

.delete-modal-content .btn-delete {
  margin-top: 55px;
}

.delete-modal-main .modal-content .modal-body {
  padding: 32px 45px;
}

.delete-modal-main .modal-dialog {
  max-width: 600px !important;
  max-height: 210px !important;
  margin-top: 23vh;
  box-shadow: 0px 0px 11px 1px #545454;
  border-radius: 16px;
}

.export-modal-main .modal-content {
  background: #1f2328;
  border-radius: 16px;
}

.export-modal-main .modal-content .modal-body {
  padding: 32px 45px;
}

.export-modal-main .modal-dialog {
  max-width: 600px !important;
  max-height: 210px !important;
  margin-top: 23vh;
  box-shadow: 0px 0px 11px 1px #545454;
  border-radius: 16px;
}

.page-devider {
  width: 100%;
  background-color: rgba(235, 238, 246, 0.42);
  height: 1px;
  margin: 34px 0px 25px 0px;
}

.slectchainlogo-main {
  display: flex;
  flex-wrap: wrap;
}

.slectchainlogo {
  border: 1px solid #ebeef6;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 20px 15px;
  text-align: center;
  margin-bottom: 15px;
  width: 200px;
  height: 92px;
  flex-wrap: wrap;
  margin-right: 15px;
}

.slectchainlogo:hover {
  border: double 2px transparent;
  background-image: linear-gradient(white, white), radial-gradient(circle at top left, #7b33eb, rgba(1, 100, 216, 0.71));
  background-origin: border-box;
  background-clip: content-box, border-box;
  padding: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
}

label.amount-label {
  font-family: "Roboto Bold";
  font-size: 13px;
  line-height: 15px;
  color: #cad6ec;
  margin-top: 23px;
}

.continue-btn {
  background: linear-gradient(90deg, #7d33eb 5.44%, rgba(14, 98, 175, 0.71) 98.16%);
  border-radius: 8px;
  font-family: "Roboto Bold";
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;
  border: none;
  padding: 13px 42px;
  position: relative;
}

.continue-btn img {
  position: absolute;
  right: 15px;
  top: 18px;
}

.selectContacts-cont h3 {
  font-family: "Roboto Regular";
  font-size: 22px;
  line-height: 24px;
  color: #1a1f36;
}

.selectBlockchain-cont {
  margin-bottom: 20px;
}

.selectBlockchain-cont h5 {
  font-family: "Roboto Bold";
  font-style: normal;
  font-weight: 900;
  font-size: 13px;
  line-height: 15px;
  color: #4f5b76;
  margin-bottom: 14px;
}

.selectBlockchain-cont h5 span {
  color: #f00;
}

.selectStandard-cont {
  margin-bottom: 35px;
}

.selectStandard-cont h5 {
  font-family: "Roboto Bold";
  font-style: normal;
  font-weight: 900;
  font-size: 13px;
  line-height: 15px;
  color: #4f5b76;
  margin-bottom: 14px;
}

.selectStandard-cont h5 span {
  color: #f00;
}

.slectchainlogo.active {
  border: double 2px transparent;
  background-image: linear-gradient(white, white), radial-gradient(circle at top left, #7b33eb, rgba(1, 100, 216, 0.71));
  background-origin: border-box;
  background-clip: content-box, border-box;
  width: 200px;
  height: 92px;
  padding: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.collectionDetails-form label {
  width: 100%;
  font-family: "Roboto Bold";
  font-style: normal;
  font-weight: 900;
  font-size: 13px;
  line-height: 15px;
  color: #4f5b76;
  margin-bottom: 11px;
  position: relative;
}



.collectionDetails-form input {
  background: rgba(235, 238, 246, 0.42);
  border-radius: 4px;
  border: none;
  font-family: "Roboto Regular";
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  width: 100%;
  padding: 11px 17px;
  margin-bottom: 16px;
}

.collectionDetails-form .date-input .dropdown .dropdown-toggle {
  background: rgba(235, 238, 246, 0.42);
  border-radius: 4px;
  border: none;
  font-family: "Roboto Regular";
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  width: 100%;
  padding: 0px;
  margin-bottom: 16px;
  height: 46px;
}

.collectionDetails-form .date-input .dropdown .dropdown-toggle label input {
  margin-bottom: 0px;
}

.collectionDetails-form .date-input .dropdown .dropdown-toggle label img {
  top: 14px;
}

.date-input .show.dropdown .dropdown-menu.show {
  width: 100%;
}

.collectionDetails-form .date-input .dropdown .dropdown-toggle::after {
  display: none;
}

.collectionDetails-form .date-input .react-datepicker-wrapper {
  display: inline-block;

  padding: 0;
  border: 0;
  position: absolute;
  right: 10px;
}

.collectionDetails-form .date-input .react-datepicker-wrapper img {
  position: absolute;
  right: 0px;
  top: -3px;
}

.options-forms .options-forms-lists-main .nav-item {
  margin-bottom: 10px;
}

.collectionDetails-form textarea {
  background: rgba(235, 238, 246, 0.42);
  border-radius: 4px;
  border: none;
  font-family: "Roboto Regular";
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  width: 100%;
  padding: 11px 17px;
  min-height: 158px;
  margin-bottom: 16px;
}

.collectionDetails-form .trait-switch {
  /* height: 50px; */
  margin-bottom: 10px;
  display: inline-block;
}

.collectionDetails-form .trait-switch label {
  width: 100% !important;
  float: left;
}

.collectionDetails-form .royaltyAmount {
  background: rgba(235, 238, 246, 0.42);
  border-radius: 4px;
  border: none;
  font-family: "Roboto Regular";
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  min-width: 74px;
  padding: 11px 17px;
  margin-bottom: 16px;
  max-width: 100px;
}

.collectionDetails-form .trait-switch .checkbox {
  margin-bottom: 0px !important;
  /* width: 53px; */
  height: 34px;
  left: 7px;
  top: 5px;
}

.card-active {
  border: 2px solid #5d42e7 !important;
}

.smart-butoons {
  margin-top: 36px;
}

.collectionDetails-form .trait-switch .button {
  width: 123px !important;
  height: 44px !important;
  margin-top: 5px;
}

.collectionDetails-form .trait-switch .knobs span {
  left: 6px !important;
  top: 6px !important;
  width: 56px;
  height: 32px;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.collectionDetails-form .toggle-button-cover {
  margin-left: 1px;
}

.collectionDetails-form .trait-switch #button-10 .knobs:before {
  width: 56px;
  height: 32px;
  left: 6px;
  top: 6px;
}

.collectionDetails-form .trait-switch #button-10 .checkbox:checked+.knobs:before {
  left: 61px;
}

.collectionDetails-form .trait-switch #button-10 .knobs:after {
  content: "No";
  right: 26px;
  top: 15px;
  color: #4e4e4e;
}

.collectionDetails-form input::placeholder,
.collectionDetails-form textarea::placeholder {
  font-size: 16px;
  line-height: 24px;
  color: #97adc3;
  font-family: "Roboto Regular";
}

.collectionDetails-form label span {
  color: #f00;
}

.collectionDetails-form label img {
  margin-left: 4px;
  cursor: pointer;
}

.collectionDetails-form .trait-switch .toggle-button-cover .nav-tabs {
  background: #cad6ec;
  border-radius: 24px;
  padding: 5px;
  max-width: 122px;
}

.collectionDetails-form .trait-switch .toggle-button-cover .nav-tabs .nav-link {
  width: 56px;
  height: 32px;
  background: transparent;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  transition: 0.3s ease all;
  border-radius: 30px;
  padding: 2px 0px;
  color: #000;
}

.collectionDetails-form .trait-switch .toggle-button-cover .nav-tabs .nav-link.active {
  background: radial-gradient(94.85% 994.56% at 5.15% 100%, #7b33eb 12.62%, rgba(120, 33, 255, 0.74) 98.44%);
  transition: 0.3s ease all;
  border-radius: 30px;
  padding: 2px 0px;
  color: #fff;
  border-color: transparent;
}

.tab-pane {
  color: white;
}

.back-arrow-btn {
  border-radius: 8px;
  font-family: "Roboto Bold";
  font-size: 12px;
  line-height: 20px;
  color: #120625;
  border: none;
  padding: 13px 20px;
  position: relative;
  background-color: transparent;
  border: 1px solid;
  margin-right: 10px;
  text-transform: uppercase;
}

.options-forms .options-forms-lists {
  border: 1px solid #ebeef6;
  box-sizing: border-box;
  border-radius: 6px;
  width: 164px;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 10px;
  margin-right: 10px;
}

.options-forms .options-forms-lists:hover {
  display: flex;
  border-radius: 5px;
  border: double 2px transparent !important;
  margin-bottom: 10px;
  padding: 0px 0px;
  background-image: linear-gradient(white, white), radial-gradient(circle at top left, #7b33eb, rgba(1, 100, 216, 0.71));
  background-clip: content-box, border-box;
}

.options-forms .options-forms-lists h5 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-family: "Roboto Regular";
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #1a1f36;
  margin-bottom: 0px;
}

.options-forms .options-forms-lists span {
  font-family: "Roboto Regular";
  font-size: 8px;
  line-height: 24px;
  text-align: center;
  color: #1a1f36;
  width: 100%;
  float: left;
}

.options-forms .options-forms-lists-main {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  border-bottom: 0;
}

.collectionDetails-form .date-input label img {
  position: absolute;
  right: 6px;
  bottom: 30px;
}

.options-forms .options-forms-lists.active {
  display: flex;
  border-radius: 5px;
  border: double 2px transparent !important;
  background: transparent;
  color: #1f2328;
  margin-bottom: 20px;
  padding: 0px 0px;
  background-image: linear-gradient(white, white), radial-gradient(circle at top left, #7b33eb, rgba(1, 100, 216, 0.71));
  background-origin: border-box;
  background-clip: content-box, border-box;
  width: 164px;
}

.btn-done {
  background: linear-gradient(90deg, #7b33eb 21.89%, rgba(9, 133, 246, 0.71) 98.16%);
  border-radius: 8px;
  border: none;
  font-family: "Roboto Bold";
  font-size: 10px;
  line-height: 20px;
  color: #cad6ec;
  padding: 8px 16px;
  margin-left: 5px;
  text-transform: uppercase;
  margin-bottom: 2px;
}

.btn-done:hover {
  opacity: 0.9;
}

.btn-disable {
  border-radius: 8px;
  font-family: "Roboto Bold";
  font-size: 10px;
  line-height: 20px;
  color: #cad6ec;
  padding: 8px 16px;
  margin-left: 5px;
  border: 1px solid #000;
  background: transparent;
  text-transform: uppercase;
  margin-bottom: 2px;
}

button.btn-process {
  background: #0080ff;
  border-radius: 8px;
  border: none;
  font-family: "Roboto Bold";
  font-size: 10px;
  line-height: 20px;
  padding: 8px 16px;
  margin-left: 5px;
  color: #ffffff;
  text-transform: uppercase;
  margin-bottom: 2px;
}

button.btn-complete {
  background: #04cd3c;
  border-radius: 8px;
  border: none;
  font-family: "Roboto Bold";
  font-size: 10px;
  line-height: 20px;
  padding: 8px 16px;
  margin-left: 5px;
  color: #ffffff;
  text-transform: uppercase;
  margin-bottom: 2px;
}

button.btn-unlink {
  background: #e04558;
  border-radius: 8px;
  border: none;
  font-family: "Roboto Bold";
  font-size: 10px;
  line-height: 20px;
  padding: 8px 16px;
  margin-left: 5px;
  color: #ffffff;
  text-transform: uppercase;
  margin-bottom: 2px;
}

button.back-dash-btn {
  background: linear-gradient(90deg, #7d33eb 5.44%, rgba(14, 98, 175, 0.71) 98.16%);
  border-radius: 8px;
  border: none;
  font-family: "Roboto Bold";
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;
  padding: 13px 30px;
  text-transform: uppercase;
}

.PublishCollection-list h4 {
  font-family: "Roboto Regular";
  font-size: 18px;
  line-height: 24px;
  color: #1a1f36;
  padding-right: 20px;
  margin-bottom: 0px;
}

.PublishCollection-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border: 1px solid transparent;
  border-image: linear-gradient(90deg, #7b33eb 21.89%, rgba(9, 133, 246, 0.71) 98.16%);
  border-image-slice: 1;
  padding: 17px 30px;
  align-items: center;
  margin-bottom: 19px;
  min-height: 70px;
  height: 100%;
}

.PublishCollection-btns {
  margin-top: 10px;
}

.PublishCollection-btns span {
  font-family: "Roboto Regular";
  font-size: 18px;
  line-height: 24px;
  color: #1a1f36;
  margin-left: 5px;
}

.preview-modal-main .modal-content {
  background: #1f2328;
  border-radius: 16px;
}

.preview-modal-content h2 {
  font-family: "Roboto Bold";
  font-size: 24px;
  line-height: 20px;
  color: #cad6ec;
  margin-bottom: 16px;
}

.preview-modal-content p {
  font-family: "Roboto Regular";
  font-size: 14px;
  line-height: 18px;
  color: #cad6ec;
}

.preview-modal-content p a {
  color: #cad6ec;
  text-decoration: underline;
}

.preview-modal-main .modal-dialog {
  max-width: 942px;
  margin-top: 17vh;
}

.preview-modal-main .modal-content .modal-body {
  padding: 31px 38px;
}

.preview-modal-content-lists {
  display: flex;
  flex-wrap: wrap;
}

.preview-modal-list {
  background: #252a30;
  border: 0.5px solid #2e343a;
  box-sizing: border-box;
  border-radius: 5px;
  margin: 4.5px;
  padding: 14px 11px;
  margin-bottom: 10px;
  max-width: 159px;
}

.preview-modal-list img {
  width: 100%;
  height: 163.69px;
  object-fit: cover;
}

.preview-modal-content .row {
  margin-top: 14px;
}

.passport-requirements .form-check img {
  width: 17px;
  height: 17px;
  object-fit: contain;
}

.dashboard-upload button.card-btns:after {
  display: none;
}

.modal-content .crossModal {
  width: 23px;
  height: 23px;
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
}

.mint-price-mutiple {
  display: flex;
}

.mint-price-mutiple .mint-nft-fields {
  display: flex;
}

.collectionDetails-form label label {
  min-height: 21px;
}

.mint-nft-dased {
  margin-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  color: #1a1f36;
}

.collectionDetails-form .mint-nft-whitelist img {
  top: 40px;
  position: absolute;
  right: 10px;
  width: 15px;
  height: 15px;
}

.smart-deploy-modal-cont .confirm-smart-check {
  background: #252a30;
  border: 0.5px solid #2e343a;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 17px 25px;
  margin-bottom: 33px;
}

.smart-deploy-modal-cont .confirm-smart-check input.form-check-input {
  width: 16px;
  height: 16px;
  margin-top: 2px !important;
}

.smart-deploy-modal-cont .form-check-label {
  font-family: "Roboto Regular";
  font-size: 14px;
  line-height: 18px;
  color: #cad6ec;
  padding-left: 22px;
}

.smart-deploy-modal .btn-delete {
  margin-top: 33px;
}

.smart-deploy-modal-cont .btn-deploy {
  background: linear-gradient(90deg, #7b33eb 21.89%, rgba(1, 100, 216, 0.71) 98.16%) !important;
  border: none !important;
  width: 100% !important;
  font-family: "Roboto Bold";
  color: #cad6ec !important;
  padding: 18px 0px !important;
  text-transform: uppercase;
  border-radius: 6px;
  font-size: 16px;
  line-height: 24px;
}

.mints-charts {
  height: 344px;
  clear: both;
  display: initial;
}

.doughnut-chart {
  margin-top: 37px;
}

.recent-transction-table {
  margin-top: 37px;
}

.mints-charts canvas {
  background: rgba(235, 238, 246, 0.42);
  border-radius: 6px;
  padding: 21px 49px;
  margin-bottom: 37px;
}

.mints-charts canvas {
  background: rgba(235, 238, 246, 0.42);
  border-radius: 6px;
  padding: 21px 40px;
  width: 100% !important;
  min-height: 300px !important;
  max-height: 500px !important;
}

.doughnut-chart canvas {
  max-width: 200px !important;
  max-height: 200px !important;
  width: 100% !important;
  object-fit: contain;
}

.dought-chart-left-cont {
  background: rgba(235, 238, 246, 0.42);
  border-radius: 6px;
  padding: 20px;
  height: 238px;
  overflow: hidden;
}

.dought-chart-left-cont canvas {
  margin: 0 auto;
}

.dought-chart-right-cont {
  background: rgba(235, 238, 246, 0.42);
  border-radius: 6px;
  padding: 19px 26px;
}

.dought-chart-right-cont .table thead th {
  border-top: 0;
}

.dought-chart-right-cont .table tbody tr:last-child {
  border-bottom: 1px solid #dee2e6;
}

.dought-chart-right-cont .table thead th {
  border-top: 0;
  font-family: "Roboto Bold";
  font-size: 10px;
  line-height: 20px;
  color: #4f5b76;
}

.dought-chart-right-cont .table tbody tr td {
  font-family: "Roboto Regular";
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

.recent-transction-table .table tbody tr td {
  font-family: "Roboto Regular";
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

.recent-transction-table .table thead th {
  border-top: 0;
  font-family: "Roboto Bold";
  font-size: 10px;
  line-height: 20px;
  color: #4f5b76;
}

.recent-transction-table .table thead th {
  border-top: 0;
  font-family: "Roboto Bold";
  font-size: 10px;
  line-height: 20px;
  color: #4f5b76;
}

.recent-transction-table .table thead th {
  border-top: 0;
}

.recent-transction-table .table tbody tr:last-child {
  border-bottom: 1px solid #dee2e6;
}

.recent-transction-table-cont {
  background: rgba(235, 238, 246, 0.42);
  border-radius: 6px;
  padding: 19px 26px;
}

.dought-table-hdg {
  font-family: "Roboto Bold";
  font-size: 13px;
  line-height: 28px;
  color: #4f5b76;
  margin-bottom: 0px;
}

.question-mark-header {
  display: flex;
  align-items: center;
  padding-right: 35px;
}

.header-right {
  display: flex;
}

.question-mark-header img {
  border-radius: 0;
  width: 37px;
  height: 37px;
}

.trait-basic-add-trait .taits-list {
  background: #1d1e22;
  border-radius: 3px;
  margin: 4px 5px;
  padding: 2px 4px;
  border: 1px solid #cad6ec;
  font-family: "Roboto Regular";
  font-size: 10px;
  line-height: 20px;
  color: #cad6ec !important;
  margin-bottom: 0;
  margin-left: 0px;
  word-break: break-word;
}

.trait-basic-add-trait .input-group form input {
  background: #1d1e22;
  border-radius: 3px;
  width: 100%;
  border: 1px solid #cad6ec;
  font-family: "Roboto Bold";
  font-size: 10px;
  line-height: 20px;
  color: #cad6ec;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 2px 6px;
  height: 33px;
  padding-left: 40px;
}

.trait-basic-add-trait .input-group form input::placeholder {
  color: #ffffff;
}

.traitsBasic-links .tab-content {
  overflow-y: auto;
  height: 73vh;
  padding-bottom: 30px;
  overflow-x: hidden;
}

.traitsBasic-upload .upload-btn span a {
  color: #0967d2 !important;
  font-family: "Roboto Regular";
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  text-decoration: underline !important;
}

.traitsBasic-links .tab-content::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.traitsBasic-links .tab-content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.traitsBasic-links .tab-content::-webkit-scrollbar-thumb {
  background-color: #363636;
}

.preview-trait-btn img {
  margin-right: 9px;
}

.trait-basic-tab .export-trait-btn img {
  float: none !important;
  margin-right: 9px;
}

.export-trait-btn img {
  float: left;
  padding-top: 3px;
}

.rarity-modal-form-left {
  display: flex;
}

.rarity-modal-form-left .form-group:nth-child(1) {
  flex-grow: 3;
  margin-right: 10px;
}

.rarity-modal-form-left .form-group:nth-child(2) {
  flex-grow: 0;
}

.rarity-modal-form-left .form-group label {
  font-family: "Roboto Bold";
  font-size: 10px;
  line-height: 12px;
  color: #4f5b76;
}

.rarity-modal-form-right label {
  font-family: "Roboto Bold";
  font-size: 10px;
  line-height: 12px;
  color: #4f5b76;
  width: 100%;
}

.evenly-btn {
  background: rgba(157, 157, 157, 0.04);
  border: 1px solid #cad6ec !important;
  box-sizing: border-box;
  border-radius: 5px;
  width: 50%;
  display: flex;
  align-items: center;
  padding: 6px;
  margin-bottom: 10px;
  height: 43px;
  margin-right: 10px;
  font-family: "Roboto Bold";
  font-size: 10px !important;
  line-height: 20px !important;
  color: #cad6ec !important;
}

.custom-btn {
  border-radius: 5px !important;
  font-family: "Roboto Bold";
  font-size: 10px !important;
  line-height: 20px !important;
  width: 50%;
  color: #cad6ec !important;
  margin-bottom: 8px;
  border: double 2px transparent;
  background-image: linear-gradient(#1f2328, #1f2328),
    radial-gradient(circle at top left, #7b33eb, rgba(1, 100, 216, 0.71));
  background-origin: border-box;
  background-clip: content-box, border-box;
  padding: 0 !important;
  height: 43px;
}

.rarity-modal-right-btn {
  display: flex;
}

.rarity-modal-form-left .form-group select {
  height: 43px;
  font-family: "Roboto Regular";
  font-size: 13px;
  line-height: 15px;
  color: #000000;
}

.rarity-modal-form-left .form-group input {
  height: 43px;
  font-family: "Roboto Regular";
  font-size: 13px;
  line-height: 15px;
  color: #000000;
  padding: 6px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  width: 100%;
  margin-bottom: 5px;
}

.preview-modal-content-sections input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-bottom: 5px;
}

.preview-modal-content-sections .form-group {
  margin: 4.5px;
}

.preview-modal-content-sections {
  margin-bottom: 21px;
  max-width: 159px;
}

.preview-modal-content .export-trait-btn,
.preview-modal-content .preview-trait-btn {
  font-size: 14px;
  height: 59px;
}

.delete-modal-content p img {
  float: left;
  margin-right: 10px;
}

.join-dash-main a.active .smartContractsLinksContent span {
  background: transparent;
  border-radius: 0;
}

.join-dash-main a.active .smartContractsLinksContent {
  color: rgba(123, 51, 235, 1);
  font-family: "Roboto Bold";
  font-size: 14px;
  line-height: 16px;
}

.dashboard-links.join-dash-main a.active,
.dashboard-links.join-dash-main a.active span {
  background: transparent;
  font-family: "Roboto Bold";
  font-size: 9px;
  line-height: 24px;
  color: rgba(123, 51, 235, 1);
}

.dashboard-links.join-dash-main a.currentActive,
.dashboard-links.join-dash-main a.currentActive span {
  background: transparent;
  font-family: "Roboto Bold";
  font-size: 9px;
  line-height: 24px;
  color: rgba(123, 51, 235, 1);
}

.dashboard-links.join-dash-main a:hover,
.dashboard-links.join-dash-main a:hover span {
  background: transparent;
}

.embed-modal-main .modal-dialog {
  max-width: 704px;
}

.embed-modal-main .nav-tabs {
  background: #f7f9fb;
  border-radius: 30px;
  max-width: 197px;
  margin-bottom: 29px;
}

.embed-modal-main .nav-tabs li a {
  font-family: "Roboto Bold";
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  color: #1f2328;
}

.embed-modal-main .nav-tabs .nav-item {
  width: 50%;
}

.embed-modal-main .nav-link {
  background: #fff;
  border-radius: 30px;
  font-family: "Roboto Bold";
  font-size: 10px;
  line-height: 12px;
  color: #1f2328;
  border-radius: 30px !important;
  width: 100%;
  height: 44px;
}

.embed-modal-main .nav-link.active {
  background: radial-gradient(94.85% 994.56% at 5.15% 100%, #7b33eb 12.62%, rgba(120, 33, 255, 0.74) 98.44%);
  color: #ffffff !important;
  border: 3px solid #fff !important;
}

.metadata-script-cont-mdl {
  background: #252a30;
  border: 0.5px solid #2e343a;
  box-sizing: border-box;
  border-radius: 5px;
  min-height: 448px;
  padding: 28px 29px;
  color: #fff;
}

.review-metadata-modal.modal.show .modal-dialog {
  max-width: 704px;
}

.embed-modal-main p {
  font-family: "Roboto Regular";
  font-size: 14px;
  line-height: 18px;
  color: #cad6ec;
  margin-bottom: 24px;
}

.btn-copy {
  background: linear-gradient(90deg, #7b33eb 18.67%, rgba(26, 140, 255, 0) 169.21%);
  border-radius: 6px;
  border: none;
  width: 100%;
  margin: 14px 0px 31px 0px;
  font-family: "Roboto Bold";
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #ffffff;
  justify-content: center;
  height: 56px;
}

.btn-copy:hover {
  opacity: 0.8;
}

.background-color-btn button {
  /* background: rgba(43, 50, 61, 0.7); */
  border-radius: 4px;
  border: none;
  width: 32px;
  height: 32px;
}

.button-color-btn button {
  background: rgba(82, 96, 230, 0.7);
  border-radius: 4px;
  border: none;
  width: 32px;
  height: 32px;
}

.background-and-button-main {
  display: flex;
  max-width: 80px;
  margin-bottom: 24px;
}

.background-color-btn {
  margin: 0 13px 0 auto;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.button-color-btn {
  margin: 0 13px 0 auto;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.background-color-btn span {
  font-family: "Roboto Regular";
  font-size: 5px;
  line-height: 18px;
  color: #cad6ec;
  letter-spacing: 1px;
}

.button-color-btn span {
  font-family: "Roboto Regular";
  font-size: 5px;
  line-height: 18px;
  color: #cad6ec;
  letter-spacing: 1px;
}

.sketch-picker {
  position: absolute;
}

.embed-modal-editor {
  /* background: rgba(43, 50, 61, 0.6); */
  border-radius: 30px;
  padding: 11px 79px 46px 71px;
  margin-bottom: 34px;
}

.embed-modal-editor p {
  font-family: "Chakra Petch Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
  color: #ffffff;
}

.embed-modal-editor .rdw-editor-main .public-DraftEditor-content {
  font-family: "Chakra Petch Bold";
  font-size: 40px;
  line-height: 52px;
  color: #f5f8ff;
  padding: 20px;
  margin-bottom: 21px;
}

.embed-modal-editor .rdw-editor-main .public-DraftEditor-content,
.embed-modal-editor .rdw-editor-main .public-DraftEditor-content:focus {
  border: 2px solid #756127;
}

.embed-modal-editor .rdw-editor-main .public-DraftStyleDefault-ltr {
  text-align: center;
  margin: 0;
}

.btn-connect {
  /* background: rgba(82, 96, 230, 0.7) !important; */
  border-radius: 30px;
  width: 100%;
  font-family: "Chakra Petch Bold";
  font-size: 20px;
  line-height: 26px;
  color: #ffffff;
  height: 71px;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-connect img {
  margin-left: 20px;
}

.btn-connect:hover {
  color: #ffffff !important;
  background: rgb(82 96 230 / 48%) !important;
}

.embed-modal-editor .rdw-editor-toolbar {
  background: #ffffff;
  box-shadow: 0px 9px 27px rgba(0, 0, 0, 0.07), 0px 3.75998px 11.28px rgba(0, 0, 0, 0.0503198),
    0px 2.01027px 6.0308px rgba(0, 0, 0, 0.0417275), 0px 1.12694px 3.38082px rgba(0, 0, 0, 0.035),
    0px 0.598509px 1.79553px rgba(0, 0, 0, 0.0282725), 0px 0.249053px 0.747159px rgba(0, 0, 0, 0.0196802);
  border-radius: 6px !important;
  width: fit-content;
}

.embed-modal-editor .rdw-option-wrapper img {
  width: 8px;
}

.contract-revenue .total-revenue {
  background: #1d1e22;
  border-radius: 6px;
  padding: 10px 15px;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
}

.contract-revenue .total-revenue span {
  font-family: "Roboto Bold";
  font-size: 10px;
  line-height: 20px;
  color: #ffffff;
  margin-right: 10px;
}

.contract-revenue .total-revenue input {
  font-family: "Roboto Regular";
  font-size: 10px;
  line-height: 20px;
  color: #ffffff;
  background-color: transparent;
  border: none;
}

.contract-revenue .total-revenue input::placeholder {
  color: #ffffff;
}

.right-menu-content .right-menu-form label textarea {
  background: #f7f9fb;
  border: 1px solid #cad6ec;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  min-height: 102px;
  padding: 9px 15px;
  font-family: "Roboto Regular";
  font-size: 7px;
  line-height: 20px;
  color: #000000;
}

.withdrow-btn {
  background: linear-gradient(90deg, #7b33eb 21.89%, rgba(9, 133, 246, 0.71) 98.16%);
  border-radius: 8px;
  border: none;
  width: 100%;
  font-family: "Roboto Bold";
  font-size: 16px;
  line-height: 24px;
  color: white;
  height: 56px;
  margin: 11px 0px 8px 0px;
}

.miting-tabs .nav-item .nav-link {
  background: transparent;
  border: none;
  font-family: "Roboto Bold";
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  color: #1f2328;
  height: 35px;
}

.miting-tabs .nav-item .nav-link.active {
  background: radial-gradient(94.85% 994.56% at 5.15% 100%, #7b33eb 12.62%, rgba(120, 33, 255, 0.74) 98.44%);
  border-radius: 30px;
  border: none;
  color: #ffffff;
}

.miting-tabs .nav-tabs {
  background: #f7f9fb;
  border-radius: 30px;
  width: fit-content;
  height: 44px;
  padding: 5px;
}

.withdrow-right-sidebar label {
  font-family: "Roboto Bold";
  font-size: 9px;
  line-height: 11px;
  color: #cad6ec;
  width: 100%;
  position: relative;
}

.withdrow-right-sidebar label textarea {
  background: #f7f9fb;
  border: 1px solid #cad6ec;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  min-height: 102px;
  padding: 9px 15px;
}

.right-menu-content input.copy-input {
  background: #f7f9fb;
  border: 1px solid #cad6ec;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  height: 33px;
  padding: 8px;
}

.options-forms-lists-main .nav-link {
  border: 2px solid #ebeef6 !important;
  background-color: transparent;
  width: 164px;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-radius: 6px;
  margin-right: 18px;
  font-family: "Roboto Regular";
  font-size: 16px;
  line-height: 24px;
}

.options-forms-lists-main .nav-link.active {
  display: flex;
  border-radius: 5px;
  border: double 2px transparent !important;
  background: transparent;
  color: #1f2328;
  margin-bottom: 20px;
  padding: 0px 0px;
  background-image: linear-gradient(white, white), radial-gradient(circle at top left, #7b33eb, rgba(1, 100, 216, 0.71));
  background-origin: border-box;
  background-clip: content-box, border-box;
  width: 158px;
}

.question-mark-header {
  background-color: transparent !important;
  border: none !important;
  height: 84px;
  padding-right: 35px !important;
}

.question-mark-header:focus {
  outline: none !important;
  box-shadow: none !important;
}

.question-mark-header.dropdown-toggle::after {
  display: none;
}

.question-mark-header-text {
  color: #ff6191;
  font-weight: bolder;
  font-family: "Roboto Regular";
  margin-top: 14px;
  text-shadow: 2px 1px 2px white, -1px -1px 2px white, -2px 1px 2px white, 1px -1px 2px white;
  font-size: 18px;
}

.header-menu-open {
  max-width: 295px !important;
  float: right;
  padding: 0;
}

.question-mark-main .dropdown-menu.show::before {
  display: none;
}

.question-mark-main.show.dropdown .dropdown-menu.show {
  padding: 0;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  margin-top: -2px;
}

.header-menu-open a:last-child {
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.arrow-left::before {
  left: 99%;
  top: 20%;
  border-bottom: 10px solid #000 !important;
  transform: rotate(89deg);
  z-index: -1;
}

.arrow-left::before {
  content: "";
  width: 0px;
  height: 0px;
  border: 0.8em solid transparent;
  position: absolute;
  top: 7px;
}



/* .TraitsBasic-main-div .content-main.left-menu.right-menu {
  padding: 100px 50px 47px 50px;
} */

.archive-tabs-buttons {
  display: flex;
  justify-content: space-between;
  /* flex-wrap: wrap; */
  padding: 0px 50px;
}

.archive-tabs-button-right {
  position: absolute;
  right: 20px;
  top: 12px;
}

.archive-tabs-buttons .archive-tabs-button-right {
  display: flex;
  justify-content: flex-end;
  text-align: right;
  width: 15%;
}

.archive-tabs-cont {
  padding: 0px 50px;
}

.btn-default {
  background: #ffffff;
  border: 2px solid #000000;
  box-sizing: border-box;
  border-radius: 5px;
  font-family: "Roboto Regular";
  font-size: 10px;
  line-height: 20px;
  color: #000000;
  min-width: 90px;
  height: 31px;
  margin-top: 6px;
  margin-bottom: 6px;
  margin-right: 9px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.btn-default:hover {
  background-color: #000000;
  color: #fff;
}

.btn-default .dot {
  background-color: #000000;
}

.btn-default:hover .dot {
  background-color: #ffffff;
}

.btn-male {
  background: #ffffff;
  border: 2px solid #7b33eb;
  box-sizing: border-box;
  border-radius: 5px;
  font-family: "Roboto Regular";
  font-size: 10px;
  line-height: 20px;
  color: #7b33eb;
  min-width: 90px;
  height: 31px;
  margin-top: 6px;
  margin-bottom: 6px;
  margin-right: 9px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.btn-male:hover {
  background-color: #7b33eb;
  color: #fff;
}

.btn-male .dot {
  background-color: #7b33eb;
}

.btn-male:hover .dot {
  background-color: #ffffff;
}

.btn-female {
  background: #ffffff;
  border: 2px solid #0080ff;
  box-sizing: border-box;
  border-radius: 5px;
  font-family: "Roboto Regular";
  font-size: 10px;
  line-height: 20px;
  color: #0080ff;
  min-width: 90px;
  height: 31px;
  margin-top: 6px;
  margin-bottom: 6px;
  margin-right: 9px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.archive-tabs-button-left {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.btn-female:hover {
  background-color: #0080ff;
  color: #fff;
}

.btn-female .dot {
  background-color: #0080ff;
}

.btn-female:hover .dot {
  background-color: #ffffff;
}

.btn-archive-add {
  background: #1d1e22;
  border-radius: 3px;
  color: #fff;
  width: 20px;
  height: 31px;
  border: none;
  padding: 0px;
}

.btn-watch {
  background: #ffffff;
  border: 1px solid #d5ddec;
  box-sizing: border-box;
  border-radius: 30px;
  width: 111px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 20px;
  color: #afbacf;
  height: 29px;
  margin-bottom: 12px;
}

.btn-watch img {
  width: 15px;
  margin-left: 5px;
}

.archive-tabs-button-left button img {
  margin-left: 9px;
}

.archive-tabs-button-left button .dot {
  height: 9px;
  width: 9px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 11px;
}

.archive-tabs-devider {
  border: 1px solid #e9eff5;
  margin-bottom: 31px;
  width: 100%;
}

.archive-list-btn {
  background: #1d1e22;
  border: 1px solid #cad6ec;
  box-sizing: border-box;
  border-radius: 5px;
  font-family: "Roboto Bold";
  font-size: 10px;
  line-height: 20px;
  color: #cad6ec;
  width: 99px;
  height: 47px;
  z-index: 1;
  position: relative;
}

.archive-list-btn:hover {
  background-color: #1d1e22eb;
  border: 1px solid #fa8836;
}

.archive-list-btn:focus {
  outline: 1px;
  border: 2px solid #fa8836;
}

.archive-tabs-cont-list {
  background-image: url(../public/images/dots-background.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
  padding: 50px;
  display: flex;
  width: 100%;
}

.archive-tabs-cont-list ul:nth-child(1) {
  display: flex;
  justify-content: space-between;
  list-style: none;
  position: relative;
  align-items: center;
  padding-left: 0;
  width: 70%;
}

.archive-tabs-cont-list ul:nth-child(2) {
  display: flex;
  list-style: none;
  position: relative;
  align-items: center;
  padding-left: 0;
  width: 24%;
  float: right;
  justify-content: flex-end;
}

.archive-tabs-cont-list li:before {
  border: 1px dashed;
  width: 90%;
  content: "";
  position: absolute;
  left: 8%;
  top: 50%;
  z-index: 0;
}

.doted-line-cont {
  background: #e2e2e2;
  border-radius: 30px;
  font-family: "Roboto Bold";
  font-size: 6px;
  line-height: 20px;
  display: flex;
  color: #1d1e22;
  width: 41px;
  height: 16px;
  justify-content: center;
  align-items: center;
  z-index: 3;
}

.archive-muti-btn-list {
  display: grid;
  position: relative;
}

.archive-muti-btn-list .archive-list-btn {
  margin-top: 9px;
  margin-bottom: 9px;
}

.doted-line1 {
  width: 147px;
  height: 20px;
  background: transparent;
  border: none;
  border-top: dashed 1px #7b33eb;
  padding: 0;
  border-radius: 50%;
  margin: -14px -128px 0;
  z-index: 0;
  transform: rotate(330deg);
}

.doted-line1 .doted-line-cont {
  margin-left: 40%;
  margin-top: -8px;
}

.doted-line2 {
  width: 147px;
  height: 0;
  background: transparent;
  border: dashed 1px #7b33eb;
  padding: 0;
  border-radius: 50%;
  margin: -33px -129px 0;
  z-index: 0;
  transform: rotate(360deg);
}

.doted-line2 .doted-line-cont {
  margin-left: 40%;
  margin-top: -8px;
}

.doted-line3 {
  width: 147px;
  height: 20px;
  background: transparent;
  border: none;
  border-bottom: dashed 1px #e04558;
  padding: 0;
  border-radius: 50%;
  margin: -72px -128px 0;
  z-index: 0;
  transform: rotate(24deg);
}

.doted-line3 .doted-line-cont {
  margin-left: 40%;
  margin-top: 10px;
}

.doted-line1::after {
  width: 2px;
  height: 2px;
  background-color: #000;
}

li.archive-muti-btn-list::before {
  display: none;
}

button.archive-list-btn.btn-one::after {
  background: transparent;
  border: none;
  border-top: dashed 1px #7b33eb;
  padding: 0;
  border-radius: 50%;
  margin: -14px -128px 0;
  z-index: 0;
  transform: rotate(330deg);
  width: 100%;
}

.doted-line1 .doted-line-cont {
  transform: rotate(29deg);
}

.doted-line3 .doted-line-cont {
  transform: rotate(336deg);
}

.archive-tabs-main {
  background-color: #ffffff;
  display: flex;
  flex: 8;
  padding: 56px 18px 24px 18px;
  position: relative;
  overflow-y: auto;
  height: 850px;
  display: flex;
  flex-direction: column;
}

.dash-active-select .dropdown {
  background: #1d1e22;
  border: 1px solid #cad6ec;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: "Roboto Regular";
  font-size: 16px;
  line-height: 20px;
  color: #cad6ec;
  max-width: 154px;
  width: 100%;
}

.dash-active-select {
  position: relative;
}

.dash-active-select .dropdown button::after {
  border: none;
  background-image: url(../public/images/down-icon.png);
  width: 14px;
  height: 6.64px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: absolute;
  right: 10px;
  top: 23px;
}

.dash-active-select select {
  background-color: transparent !important;
  color: #CAD6EC !important;
  box-shadow: none !important;
  font-size: 16px;
  padding: 10px;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
}

.form-select {
  background-image: url(/src/asset/icons/select-icon.svg) !important;
  background-size: 14px 12px !important;
}

.select-icon {
  position: absolute;
  top: 20px;
  right: 25px;
}

.dash-active-select select:active {
  outline: none;
}

.dash-active-select select:focus {
  border: 1px solid #cad6ec;
  outline: none;
}

.dash-active-select select option {
  color: #1d1e22;
}

.dash-active-select .dropdown button {
  color: #cad6ec;
  font-size: 16px;
  line-height: 20px;
  width: 100%;
  text-align: left;
  height: 50px;
}

.tags-right-side .styles_chip__i-PT7.styles_show__3kLFl {
  position: absolute;
  z-index: 1;
  display: flex;
  height: auto;
  background: #1f2328;
  border-radius: 5px;
  font-family: "Roboto Bold";
  font-size: 7px;
  line-height: 15px;
  text-align: center;
  color: #ffffff;
  align-items: center;
}

.tags-right-input .css-1rhbuit-multiValue {
  background: #1f2328;
  border-radius: 5px;
}

.tags-right-input .css-12jo7m5 {
  font-family: "Roboto Bold";
  font-size: 7px;
  line-height: 15px;
  text-align: center;
  color: #ffffff;
}

.save-draft-btn {
  border-radius: 5px;
  font-family: "Roboto Bold";
  font-size: 10px;
  line-height: 20px;
  color: #cad6ec;
  width: 100%;
  margin-bottom: 8px;
  border: 1px solid;
  background-origin: border-box;
  background-clip: content-box, border-box;
  padding: 0;
  background-color: transparent;
  height: 33px;
}

.save-draft-btn:hover {
  background: linear-gradient(90deg, #7b33eb 21.89%, rgba(9, 133, 246, 0.71) 98.16%);
  border-radius: 8px;
  border: none;
  font-family: "Roboto Bold";
  font-size: 10px;
  line-height: 20px;
  color: #1f2328;
  padding: 6px;
  width: 100%;
}

.smart-btn-draft {
  padding: 14px;
  position: absolute;
  width: 100%;
  bottom: 0px;
}

.new-collection-modal-content .seekbar {
  margin: 12px 0;
  padding: 10px 24px;
  position: relative;
}

.seekbar-progress {
  height: 10px;
  background-color: #f5f5f5;
  background-image: linear-gradient(to bottom, #ebebeb 0%, #f5f5f5 100%);
  background-repeat: repeat-x;
  border-radius: 6px;
}

.seekbar-progress [role="progressbar"] {
  height: 100%;
  position: relative;
  background: linear-gradient(90deg, #7b33eb 0%, #4150e2 160.61%);
  border-radius: 6px;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
}

.seekbar-progress [role="progressbar"]:after {
  content: " ";
  display: block;
  width: 24px;
  height: 24px;
  position: absolute;
  top: -7px;
  right: -12px;
  background-color: #888;
  background: linear-gradient(90deg, #7b33eb 0%, #4150e2 160.61%);
  background-repeat: repeat-x;
  border-radius: 20px;
}

.seekbar input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  height: 100%;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background: transparent;
  outline: 0;
  border: 0;
}

.seekbar input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  display: block;
  width: 48px;
  height: 48px;
  background-color: transparent;
}

.seekbar input[type="range"]::-moz-range-thumb {
  display: block;
  width: 48px;
  height: 48px;
  background: transparent;
  border: 0;
}

.seekbar input[type="range"]::-moz-range-track {
  background: transparent;
  border: 0;
}

.seekbar input[type="range"]::-moz-focus-outer {
  border: 0;
}

.payment-select-card {
  width: 100%;
  background: rgb(251 251 251);
  border-radius: 5px;
  border: none;
  padding: 17px 20px;
  font-family: "Roboto Regular";
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  height: 64px;
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  position: relative;
}

.payment-select-card img {
  margin-right: 10px;
  width: auto;
}

.rangeslider-horizontal .rangeslider__fill {
  background: linear-gradient(90deg, #7b33eb 0%, #4150e2 160.61%) !important;
  background-color: transparent !important;
}

.rangeslider-horizontal .rangeslider__handle {
  width: 35px !important;
  height: 35px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-size: 12px !important;
  color: #fff;
}

.rangeslider .rangeslider__handle {
  background: #5d42e7 !important;
  border: none !important;
}

.rangeslider-horizontal .rangeslider__handle:after {
  display: none !important;
}

.dashboard-links .currentActive .smartContractsLinks {
  background: linear-gradient(90deg, #7b33eb 37.35%, #2565a6 100.9%);
}

.dashboard-links .currentActive .smartContractsLinks span {
  color: #2b323d !important;
}

.left-menu-main.account-page .dashboard-links a {
  margin-bottom: 22px;
}

.review-metadata-modal .modal-body {
  padding: 31px 44px 56px 39px !important;
}

.smart-deploy-modal-cont p {
  background: #252a30;
  border: 0.5px solid #2e343a;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 21px 27px;
  font-family: "Roboto Regular";
  font-size: 14px;
  line-height: 18px;
  color: #cad6ec;
  min-height: 63px;
}

.embed-modal-editor .public-DraftEditorPlaceholder-root {
  position: absolute;
  z-index: 0;
  font-family: "Chakra Petch Bold";
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 52px;
  color: #f5f8ff;
  left: 0;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.embed-modal-editor .rdw-editor-main .public-DraftEditor-content {
  border: 2px solid #756127;
}

.reporting-main .mint-chart-datepicker-right {
  position: relative;
  width: 50%;
  float: right;
  text-align: right;
}

.reporting-main .mint-chart-cont {
  display: flex;
  align-items: center;
}

.reporting-main .mint-chart .mint-chart-cont h1 {
  font-family: "Roboto Bold";
  font-size: 13px;
  line-height: 28px;
  color: #4f5b76;
  margin-bottom: 3px;
  width: 50%;
  float: left;
}

.reporting-main .mint-chart-datepicker-right .react-datepicker-wrapper {
  position: absolute;
  right: 10px;
}

.reporting-main .mint-chart-datepicker-right input {
  background: #ffffff;
  border: 1px solid #ece9f1;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: "Poppins Regular";
  font-size: 14px;
  line-height: 22px;
  color: #11263c;
  padding: 3px 10px;
}

.reporting-main .mint-chart-datepicker-right .react-datepicker-wrapper img {
  cursor: pointer;
}

.reporting-main .react-datepicker-popper[data-placement^="bottom"] {
  padding-top: 10px;
  right: 0 !important;
}

.reporting-main .react-datepicker {
  position: absolute;
  right: 0;
}

.reporting-main .mint-chart .reporting-chart {
  background: rgba(235, 238, 246, 0.42);
  border-radius: 6px;
  padding: 49px 25px 26px 21px;
  margin-top: 4px;
  position: relative;
}

.reporting-main .canvasjs-chart-credit {
  display: none;
}

.reporting-main .canvasjs-chart-tooltip {
  background: transparent;
  box-shadow: none !important;
}

.reporting-main .mint-chart:nth-child(2) {
  margin-top: 34px;
}

.reporting-main .canvasjs-chart-tooltip div {
  font-family: "Roboto Bold" !important;
  font-size: 22px !important;
  line-height: 28px;
  text-align: center !important;
  color: #11263c !important;
}

.reporting-main .canvasjs-chart-tooltip div {
  font-family: "Roboto Bold" !important;
  font-size: 22px !important;
  line-height: 28px;
  text-align: center !important;
  color: #11263c !important;
  white-space: inherit !important;
  display: grid;
  flex-direction: row-reverse !important;
  background: #ffffff !important;
  box-shadow: 0px 8px 8px rgba(50, 50, 71, 0.08), 0px 8px 16px rgba(50, 50, 71, 0.06);
  border: none !important;
  border-radius: 10px !important;
  padding: 5px 15px !important;
  justify-items: flex-start;
}

.reporting-main .canvasjs-chart-tooltip div span {
  font-family: "Poppins Regular" !important;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: rgba(60, 60, 67, 0.6) !important;
  width: 100%;
  /* float: left; */
}

.reporting-main .canvasjs-chart-tooltip:after {
  content: "";
  position: absolute;
  width: 11px;
  height: 10px;
  background-color: #fff;
  left: 45%;
  bottom: -4px;
  transform: rotate(45deg);
}

.trail-hide {
  background-color: #f7f8fb;
  width: 61px;
  height: 10px;
  position: absolute;
  bottom: 30px;
  z-index: 5;
}

.trail-hide-1 {
  background-color: #f7f8fb;
  width: 100px;
  height: 30px;
  position: absolute;
  bottom: 15px;
  z-index: 5;
}

/* .trait-basic-background-btn.select:before {
  content: "";
  width: 0px;
  height: 0px;
  border: 0.8em solid transparent;
  position: absolute;
  left: auto;
  right: -40px;
  top: 20%;
  border-bottom: 10px solid #000 !important;
  transform: rotate(89deg);
  z-index: 999999;
} */

@media (min-width: 320px) and (max-width: 359.98px) {
  .traitsBasic-upload-list {
    width: 127px;
    height: 150px;
  }
}

@media (min-width: 320px) and (max-width: 767.98px) {
  .nftm-form-content {
    padding: 30px 15px;
  }

  .login-footer {
    display: block;
  }

  .forget-footer {
    display: block;
  }

  .embed-modal-editor {
    padding: 20px;
  }

  .archive-tabs-buttons {
    padding: 0px;
    display: block;
  }

  .delete-modal-main .modal-content .modal-body {
    padding: 20px;
  }

  .export-modal-main .modal-content .modal-body {
    padding: 20px;
  }

  .archive-tabs-buttons .archive-tabs-button-right {
    width: 100%;
  }

  .login-links {
    width: 100%;
  }

  .login-forget {
    width: 100%;
    text-align: left;
  }

  .passport-requirements .form-check {
    display: flex;
    width: 100%;
  }

  .nftm-forms-main h1 {
    text-align: center;
    margin-bottom: 20px;
  }

  .nftm-form-content h2 {
    font-size: 25px;
  }

  .signup-cont span {
    font-size: 16px;
  }

  .divider-forms {
    margin: 20px 0px;
  }

  .nftm-form-content form input {
    margin-bottom: 10px;
    padding: 20px 15px;
  }

  .content-main {
    margin-left: 0px !important;
    padding: 20px;
    margin-right: 0px !important;
  }

  .archive-tabs-main {
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding: 100px 20px 40px 20px !important;
  }

  /* .left-menu-main { */
  /* margin: -191px !important; */
  /* max-width: 0px; */
  /* } */

  .left-menu-main.smart-left-menus {
    margin: -351px !important;
  }

  .archive-tabs-buttons {
    padding: 0px;
  }

  .archive-tabs-cont {
    padding: 0px;
  }



  /* .TraitsBasic-main-div .content-main.left-menu.right-menu {
    padding: 100px 20px 47px 20px !important;
  } */

  .right-menu-main {
    margin-right: -306px !important;
    max-width: 0px;
    display: none;
  }

  .header-menu-open a {
    padding: 10px 15px;
  }

  .header-menu-open a img {
    margin-right: 10px;
    width: 30px;
    height: auto;
  }

  .header-profile .dropdown button span {
    display: none;
  }

  .header-profile {
    padding-right: 10px;
    width: auto !important;
    padding-left: 0px;
  }

  .header-profile span {
    display: none;
  }

  .header-profile .dropdown button {
    padding: 10px;
  }

  .header-profile .dropdown-menu {
    min-width: fit-content;
    width: 100%;
    padding: 0;
  }

  .dashboard-upload .dash-upload-right {
    text-align: left;
    padding-top: 20px;
    width: 100%;
    justify-content: space-between;
    display: flex;
  }

  .traitsBasic-upload {
    padding: 20px;
  }

  .content-main-black {
    padding: 100px 20px 0px 20px !important;
  }

  /* .content-main {
    padding: 100px 20px 40px 20px !important;
  } */

  .dashboard-upload .dropdown-menu.show {
    left: -165px !important;
  }

  .dropdown-menu.show::before {
    right: 0;
    left: auto !important;
  }

  .dash-active-select {
    margin-top: 10px;
  }

  .dashboard-upload {
    padding-bottom: 115px;
  }

  .header-profile .dropdown-menu.show .header-menu-open .dropdown-item:last-child {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .question-mark-header {
    padding-right: 10px !important;
    margin-left: 10px;
  }

  .header-logo a {
    font-size: 30px;
    padding-left: 10px;
  }

  .header-logo {
    justify-content: start;
    margin-left: 5px;
  }

  .question-mark-header img {
    width: 25px;
    height: 25px;
  }

  .header-profile img {
    width: 30px;
    height: 30px;
    margin-right: 0px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .left-menu-main {
    max-width: 200px;
  }

  /* .archive-tabs-main {
    margin-left: 200px;
  } */

  .content-main.left-menu {
    margin-left: 200px;
    padding: 100px 20px 10px 20px !important;
  }

  .archive-tabs-buttons {
    padding: 0px 20px;
  }

  .archive-tabs-cont {
    padding: 0px 20px;
  }
}

@media (max-height: 700px) {
  .traitsBasic-links .tab-content {
    height: 60vh;
  }
}

@media (max-width: 1199.98px) {
  .archive-tabs-cont {
    max-width: 800px;
    overflow-x: scroll;
  }

  .archive-tabs-cont-list {
    padding: 10px;
    justify-content: space-between;
    overflow-x: auto;
    width: 700px;
    height: 250px;
  }
}

@media (min-width: 1200px) and (max-width: 1449.98px) {
  .archive-tabs-cont-list {
    padding: 0px;
    display: flex;
    justify-content: space-between;
  }

  /* .content-main {
    padding: 95px 20px 47px 20px;
  } */

  .archive-list-btn {
    width: auto;
    padding: 10px;
  }
}

@media (min-width: 1450px) {
  .archive-tabs-cont-list ul:nth-child(2) {
    width: 215px;
  }
}

/* Error message */
.error-message {
  margin-top: 3px;
  color: #e74c3c;
  font-size: 15px;
  padding: 0px 4px;
  min-height: 24px;
}

.input-field-box {
  width: 93%;
}

.card-img-top {
  flex-shrink: 0;
  object-fit: fill;
}

.smart-navtab {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 2px auto;
}

.dropdown-toggle::after {
  margin-left: 1.255em !important;
}

#header-menu {
  margin-top: -21px !important;
  margin-right: -33px !important;
}

.trail-start {
  color: #97adc4;
}

.smartContractsSteps {
  font-size: 22px !important;
}

.quetionmakrIcon {
  fill: #cad6ec;
  cursor: pointer;
  margin-left: 10px;
}

.quetionmakrIcon:hover {
  fill: #7d33eb;
}

.signup-cont .trail-start {
  font-family: "Roboto Regular";
  font-size: 20px;
  line-height: 20px;
  color: #97adc4;
  margin-top: 27px;
  display: block;
}

.resend-code-btn {
  border: none;
  background-color: transparent;
  font-family: "Nunito Regular";
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  text-decoration-line: underline;
  color: #0967d2;
}

.resend-code-btn img {
  width: 17px;
  height: 17px;
  object-fit: contain;
  margin-right: 10px;
}

.character-modal-main .modal-body input {
  background: rgba(157, 157, 157, 0.04);
  border-radius: 5px;
  width: 100%;
  line-height: 34px;
  padding: 5px 10px;
  border: 1px solid #e2e7ec;
}

.character-modal-main .modal-footer button {
  background: linear-gradient(90deg, #7b33eb 21.89%, rgba(9, 133, 246, 0.71) 98.16%);
  border-radius: 8px;
  border: none;
  font-family: "Roboto Regular";
  font-size: 13px;
  line-height: 20px;
  color: #ffffff;
  padding: 11px;
  width: 100%;
}

.react-datepicker {
  display: flex !important;
}

.billingPlan {
  text-align: center !important;
  font-size: 50px !important;
  color: #fff !important;
  margin-top: 100px;
}

.select .arrow-left {
  position: absolute !important;
  right: 0 !important;
  display: block !important;
}

.arrow-left {
  display: none;

}

.error {
  font-size: 7px;
}

.doughnut-chart-main {
  position: relative;
}

.doughnut-chart-text {
  position: absolute;
  width: 100%;
  height: 40px;
  top: 54%;
  left: 0;
  margin-top: -20px;
  line-height: 19px;
  text-align: center;
  z-index: 999999999999999;
  font-family: 'Poppins Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 46px;
  text-align: center;
  color: #5260E6;
  z-index: 0;
}

.rarity-settings-modal-main .modal-dialog {
  max-width: 942px !important;
  max-height: 624px !important;
  margin-top: 17vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rarity-settings-modal-main .modal-content {
  border-radius: 16px;
  background: #1d1e22;

}

.lodader-body {
  width: 100%;
  height: 126px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-main {
  display: inline-block;

}

.loader-main span {
  color: #1d1e22;
  width: 50px;
  height: 50px;
}

.traitsBasic-main {
  width: 100%;
}

.lodader-body-prev {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-main-prev {
  display: inline-block;
}

.loader-main-prev span {
  color: #CAD6EC;
  width: 50px;
  height: 50px;
}

#inputFileUpload {
  display: none;
}

.errorMessageInput {
  border: 1px solid red !important;
}

.dashboard-search {
  position: relative;
}

.rarity-button {
  border: 1px solid #7B33EB !important;
}

.disabled-rarity {
  background: rgb(124 53 235 / 56%) !important;
}

.confirm-modal-content-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.confirm-modal-content-text {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
}

.confirm-modal-content-text img {
  text-align: center;
  margin-right: 10px;
}

.confirm-modal-content-div h1 {
  font-family: "Roboto Bold";
  font-weight: 900;
  font-size: 24px;
  line-height: 20px;
  color: #CAD6EC;
}

.export-modal-button {
  /* width: 124px; */
  background: linear-gradient(90deg, #7b33eb 21.89%, rgba(1, 100, 216, 0.71) 98.16%) !important;
  border: none !important;
  /* width: 105% !important; */
  font-family: "Roboto Bold";
  font-size: 20px !important;
  line-height: 20px !important;
  color: #cad6ec !important;
  padding: 15px 32px !important;
  text-transform: uppercase;
  border-radius: 6px;
}

.confirm-mdl-content {
  font-family: "Roboto Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: #CAD6EC;
}

.disable-export-button {
  background-color: #2e343a54 !important;
  cursor: auto !important;
}

.react-flow__node-default,
.react-flow__node-input,
.react-flow__node-output,
.react-flow__node-group {
  background: #1D1E22 !important;
  color: #CAD6EC !important;
  border: none !important;
  font-family: 'Roboto Bold' !important;
}

.react-flow__edge-textbg {
  width: 34px;
  height: 14px;
  fill: #E2E2E2 !important;
  rx: 8px;
  ry: 10px;
  x: -6px;
  y: 0;
}

.react-flow__edge-text {
  font-size: 9px !important;
  font-family: 'Roboto Bold' !important;
}

.advanced-tabs-content {
  width: 100%;
  height: 350px;
  margin-bottom: 20px;
  transition: .3s;
}

.confirm-order-modal-main .modal-dialog {
  margin: 50px auto;
  border-radius: 16px;
  max-width: 576px !important;
}

.confirm-order-modal-main .modal-content {
  border-radius: 16px;
}

.confirm-order-modal .modal-content {
  background-color: #1f2328;
}

.confirm-order-modal-main .modal-content .modal-body {
  padding: 30px;
}

.card-number {
  width: 100%;
  background: rgb(251 251 251);
  border-radius: 3px;
  padding: 19px 10px;
  margin: 3px 0;
}

.collectionDescription-input {
  background: rgb(251 251 251);
  border-radius: 5px;
  padding: 19px 10px;
  margin: 3px 0;
  border: 1px solid transparent;
}

/* .collectionDescription-disabled {
  color: silver !important;
} */

.promo-code-box {
  width: 100%;
  height: auto;
  position: relative;
}

.promo-code-box input {
  border-radius: 5px;
  border: none;
  width: 100%;
  padding: 15px 20px;
  margin-top: 5px;
  outline: none;
  background: #fbfbfbfb;
}

.InputElement input {
  color: #0080ff !important;

}

.input-lable {
  margin-top: 8px;
}

.error-input {
  border: 1px solid red !important;
}

.payment-body {
  position: relative;
}

.payment-main {
  position: absolute;
  top: -100px;
  transition: .5s;
  opacity: 0;
}

.payment-show {
  position: static !important;
  opacity: 1;
}

.error-payment-input {
  border: 1px solid red;
}

.error-payment-message {
  color: red !important;
  padding: 0 5px;
}

.elementDisabled {
  opacity: 0.8;
}
